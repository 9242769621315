import { FC, KeyboardEventHandler, useMemo, useState } from 'react';

import { styled, css, Box } from '@mui/material';

import { throttle } from 'lodash/fp';
import { observer } from 'mobx-react';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';
import { useStores } from 'mobx/hooks/useStores';

import PatientsFetcher, { SearchedPatient } from 'fetchers/PatientsFetcher';

import { SECOND_IN_MILLISECONDS } from 'utils/DateUtils';
import { omitFalsyValuesFromQuery } from 'utils/serverFiltersUtils';

import { API_URLS } from 'constants/apiUrls';

import { FeatureIntroCodes } from 'models/Doctor';

import { HEADER_HEIGHT } from 'containers/Layout/Layout.constants';

import { Tooltip } from 'components/Tooltip';
import {
  AdvancedSearchBarActions,
  AdvancedSearchFormFields
} from 'components/UIkit/atoms/AdvancedSearchBar/AdvancedSearchBar.types';

import { getNewEmptyPatient } from 'components/UIkit/atoms/AdvancedSearchBar/AdvancedSearchBar.utils';
import { AdvancedSearchBox } from 'components/UIkit/atoms/AdvancedSearchBar/AdvancedSearchBox';
import { AdvancedSearchMenu } from 'components/UIkit/atoms/AdvancedSearchBar/AdvancedSearchMenu';
import { BasicSearchBox } from 'components/UIkit/atoms/AdvancedSearchBar/BasicSearchBox';
import { PatientsResultsMenu } from 'components/UIkit/atoms/AdvancedSearchBar/PatientsResultsMenu';
import { RecentPatientsMenu } from 'components/UIkit/atoms/AdvancedSearchBar/RecentPatientsMenu';

interface Props {
  actions: AdvancedSearchBarActions;
  hasRecentPatientsMenu?: boolean;
  onNewPatientClick?: () => void;
  hasSameWidthModifier?: boolean;
  includeSelf?: boolean;
  searchBarMenusProps?: {
    variant?: 'rounded';
    maxWidth?: string;
    advancedMenu?: { numberOfRows?: 1 | 2 };
    patientsMenu?: { hasAddNewPatientOption?: boolean; maxHeight?: string };
    offset?: [number, number];
  };
  searchBarBoxProps?: {
    placeholder?: string;
    variant?: 'dark' | 'light';
    hasPasteMrnOption?: boolean;
    advancedBox?: {
      maxWidth?: string;
    };
    testHook?: string;
    searchButtonTestHook?: string;
  };
}

export const AdvancedSearchBar: FC<Props> = observer(
  ({
    actions,
    searchBarBoxProps,
    includeSelf = false,
    hasRecentPatientsMenu = false,
    hasSameWidthModifier = false,
    searchBarMenusProps
  }) => {
    const [searchResults, setSearchResults] = useState<{
      patients: SearchedPatient[] | null;
      hasMore: boolean;
    }>({ patients: null, hasMore: false });
    const [isSearching, setIsSearching] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [cursor, setCursor] = useState(-1);
    const [isSearchBarClicked, setIsSearchBarClicked] = useState(false);
    const [isSearchStarted, setIsSearchStarted] = useState(false);
    const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
    const [advancedSearchMetrics, setAdvancedSearchMetrics] =
      useState<Partial<AdvancedSearchFormFields> | null>(null);

    const { userStore, patientPageStore } = useStores();
    const isLoadingPatientFromEmr = useNetworkLoading(API_URLS.CREATE_PATIENT_FROM_EMR);

    const searchWithThrottle = useMemo(
      () =>
        throttle(SECOND_IN_MILLISECONDS, async (term) => {
          setIsSearching(true);

          try {
            const { patients, hasMore } = await PatientsFetcher.searchGlobalPatients({
              searchTerm: term,
              includeSelf
            });
            setSearchResults({ patients, hasMore });
          } finally {
            setIsSearching(false);
          }
        }),
      [includeSelf]
    );

    const resetComponent = () => {
      setSearchTerm('');
      setCursor(-1);
      setAdvancedSearchMetrics(null);
      setIsSearchBarClicked(false);
      setSearchResults({ patients: null, hasMore: false });
    };

    const clearSearch = () => {
      handleSearchChanged('');
      setIsSearchBarClicked(false);
      setAdvancedSearchMetrics(null);
      actions.onClearSearch && actions.onClearSearch();
    };

    const search = (isKeyboardSource?: boolean) => {
      setIsSearchStarted(true);
      const trimmedSearch = searchTerm?.trim();
      actions.onSearch && actions.onSearch(trimmedSearch, { isKeyboardSource });

      if (trimmedSearch?.length > 0) {
        searchWithThrottle(trimmedSearch);
      }
    };

    const handleSearchChanged = (newValue: string) => {
      if (newValue.length < 1) {
        resetComponent();
        setIsSearchStarted(false);
      }

      closeAdvancedSearch();
      setIsSearchBarClicked(false);
      setSearchTerm(newValue);
    };

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
      let results: SearchedPatient[] = [];

      if (hasRecentPatientsMenu && patientPageStore.recentSearchedPatients.length > 0) {
        results = patientPageStore.recentSearchedPatients;
      }

      if (searchResults.patients && searchResults.patients.length > 0) {
        results = searchResults.patients;
      }

      if (!isSearchMenuOpen) {
        return;
      }

      const maxIndex = defaultSearchBarMenuProps.patientsMenu.hasAddNewPatientOption
        ? results.length
        : results.length - 1;

      switch (e.key) {
        case 'ArrowUp':
          if (cursor >= 0) {
            setCursor((prevState) => prevState - 1);
            e.preventDefault(); // avoid changing BasicSearchBox input cursor location
          }
          break;
        case 'ArrowDown':
          if (cursor < maxIndex) {
            setCursor((prevState) => prevState + 1);
            e.preventDefault(); // avoid changing BasicSearchBox input cursor location
          }
          break;
        case 'Enter':
          if (cursor === results.length) {
            handlePatientSelected(getNewEmptyPatient(searchTerm), {
              isRecentPatient: false,
              isKeyboardSource: true,
              isNewPatient: true
            });
            return;
          }

          if (cursor > -1) {
            const selectedPatient: SearchedPatient = results[cursor];
            const selectedPatientId = selectedPatient.patientId || selectedPatient.emrPatientId;

            let isPatientFromRecent = false;

            if (hasRecentPatientsMenu && patientPageStore.recentSearchedPatients.length > 0) {
              isPatientFromRecent = Boolean(
                patientPageStore.recentSearchedPatients.find(
                  (patient) => patient.patientId === selectedPatientId
                )
              );
            }

            handlePatientSelected(results[cursor], {
              isRecentPatient: isPatientFromRecent,
              isKeyboardSource: true
            });
          }
          break;
        case 'Escape':
          resetComponent();
          break;
      }
    };

    const handlePatientSelected = async (
      patient: SearchedPatient,
      options?: { isRecentPatient?: boolean; isNewPatient?: boolean; isKeyboardSource?: boolean }
    ) => {
      setIsSearchStarted(false);

      try {
        await actions.onPatientClick(patient, {
          isRecentPatient: options?.isRecentPatient,
          isKeyboardSource: options?.isKeyboardSource,
          isNewPatient: options?.isNewPatient
        });
      } finally {
        setIsSearchBarClicked(false);

        if (hasRecentPatientsMenu) {
          patientPageStore.addToRecentSearchedPatients(patient as SearchedPatient);
        }

        resetComponent();
      }
    };

    const handleEnterClicked: KeyboardEventHandler<HTMLInputElement> = (event) => {
      if (cursor < 0 && event.key === 'Enter') {
        search(true);
        event.stopPropagation();
      }
    };

    const onSearchBarClick = () => {
      if (!hasRecentPatientsMenu || !patientPageStore.recentSearchedPatients.length) {
        return;
      }

      setIsSearchBarClicked(true);
    };

    const onSearchBarClickOutside = () => {
      setSearchResults({ patients: null, hasMore: false });
      setIsSearchBarClicked(false);
      setIsSearchStarted(false);
      closeAdvancedSearch();
    };

    const closeAdvancedSearch = () => setIsAdvancedSearchOpen(false);

    const openAdvancedSearch = () => {
      setIsAdvancedSearchOpen(true);
      actions.onOpenAdvancedSearch && actions.onOpenAdvancedSearch();
    };

    const handleAdvancedSearch = async (formValues: Partial<AdvancedSearchFormFields>) => {
      const formattedFormValues = omitFalsyValuesFromQuery(formValues);
      actions.onAdvancedSearch && actions.onAdvancedSearch(formattedFormValues);

      setIsSearching(true);

      try {
        const { patients, hasMore } = await PatientsFetcher.advancedSearchGlobalPatients(
          formattedFormValues
        );

        setSearchResults({ patients, hasMore });
      } finally {
        setIsSearching(false);
        setSearchTerm('');
        setIsAdvancedSearchOpen(false);
        setAdvancedSearchMetrics(formattedFormValues);
      }
    };

    const isLoading = isSearching || isLoadingPatientFromEmr;
    const isRecentResultsMenuOpen =
      hasRecentPatientsMenu && isSearchBarClicked && !searchResults.patients;
    const isAnimationDisabled = isSearchBarClicked && isSearchStarted;

    const defaultSearchBarMenuProps = {
      offset: searchBarMenusProps?.offset || [0, 10],
      variant: searchBarMenusProps?.variant || '',
      maxWidth: searchBarMenusProps?.maxWidth || '700px',
      advancedMenu: { numberOfRows: searchBarMenusProps?.advancedMenu?.numberOfRows || 2 },
      patientsMenu: {
        hasAddNewPatientOption: searchBarMenusProps?.patientsMenu?.hasAddNewPatientOption || false,
        maxHeight:
          searchBarMenusProps?.patientsMenu?.maxHeight || `calc(100vh - ${HEADER_HEIGHT * 2}px)`
      }
    };

    const defaultSearchBarBoxProps = {
      placeholder: searchBarBoxProps?.placeholder || 'Search Name, MRN, DOB',
      variant: searchBarBoxProps?.variant || 'dark',
      hasPasteMrnOption: searchBarBoxProps?.hasPasteMrnOption || false,
      advancedBox: { maxWidth: searchBarBoxProps?.advancedBox?.maxWidth || '' },
      testHook: searchBarBoxProps?.testHook,
      searchButtonTestHook: searchBarBoxProps?.searchButtonTestHook
    };

    //regular search menu/recently viewed menu/advanced search
    const isSearchMenuOpen =
      !!searchResults.patients ||
      isSearchBarClicked ||
      isAdvancedSearchOpen ||
      Boolean(defaultSearchBarMenuProps?.patientsMenu.hasAddNewPatientOption && searchTerm);

    const isAddNewPatientOptionActive =
      cursor === searchResults?.patients?.length ||
      (!searchResults?.patients?.length && cursor === 0);

    const getCurrentSearchBarMenu = () => {
      if (isAdvancedSearchOpen) {
        return (
          <AdvancedSearchMenu
            defaultValues={{
              name: advancedSearchMetrics?.name || searchTerm,
              dob: advancedSearchMetrics?.dob || '',
              mrn: advancedSearchMetrics?.mrn || ''
            }}
            onClose={() => {
              closeAdvancedSearch();
              resetComponent();
              actions.onCancelAdvancedSearch && actions.onCancelAdvancedSearch();
            }}
            onSearch={handleAdvancedSearch}
            onClearAll={() =>
              actions.onClearAllAdvancedSearch && actions.onClearAllAdvancedSearch()
            }
            numberOfRows={defaultSearchBarMenuProps.advancedMenu.numberOfRows}
          />
        );
      }

      if (isRecentResultsMenuOpen) {
        return (
          <RecentPatientsMenu
            patients={patientPageStore.recentSearchedPatients}
            onPatientClicked={handlePatientSelected}
            activeIndex={cursor}
            isAnimationDisabled={isAnimationDisabled}
          />
        );
      }

      return (
        <PatientsResultsMenu
          activeIndex={cursor}
          results={searchResults.patients}
          onPatientClicked={handlePatientSelected}
          isAnimationDisabled={isAnimationDisabled}
          isLoading={isLoading}
          openAdvancedSearch={openAdvancedSearch}
          hasMoreResults={searchResults.hasMore}
          searchTerm={searchTerm || advancedSearchMetrics?.name || ''}
          hasAddNewPatientOption={defaultSearchBarMenuProps.patientsMenu.hasAddNewPatientOption}
          isAddNewPatientOptionActive={isAddNewPatientOptionActive}
          maxHeight={defaultSearchBarMenuProps.patientsMenu.maxHeight}
        />
      );
    };

    const getCurrentSearchBarBox = () => {
      if (advancedSearchMetrics) {
        return (
          <AdvancedSearchBox
            isLoading={isLoading}
            advancedSearchMetrics={advancedSearchMetrics}
            openAdvancedSearch={openAdvancedSearch}
            clearSearch={clearSearch}
            onGoClicked={handleAdvancedSearch}
            variant={defaultSearchBarBoxProps.variant}
            maxWidth={defaultSearchBarBoxProps.advancedBox.maxWidth}
          />
        );
      }

      return (
        <BasicSearchBox
          searchValue={searchTerm}
          onSearchChanged={handleSearchChanged}
          onGoClicked={search}
          isLoading={isLoading}
          toggleAdvancedSearch={() => {
            setIsAdvancedSearchOpen((prevState) => !prevState);
            setIsSearchBarClicked(false);
            actions.onToggleAdvancedSearchMenu && actions.onToggleAdvancedSearchMenu();
          }}
          clearSearch={clearSearch}
          updateAdvancedSearchIntroFinished={() =>
            userStore.updateIntroFinished(FeatureIntroCodes.AdvancedSearch)
          }
          isAdvancedSearchFeatureIntroOpen={
            !userStore.currentDoctor?.hasFeature(FeatureIntroCodes.AdvancedSearch)
          }
          placeholder={defaultSearchBarBoxProps.placeholder}
          hasPasteMrnOption={defaultSearchBarBoxProps.hasPasteMrnOption}
          onPasteFromClipboard={(searchTerm) => {
            handleSearchChanged(searchTerm);
            searchWithThrottle(searchTerm);
            actions.onPasteFromClipboard && actions.onPasteFromClipboard();
          }}
          testHook={defaultSearchBarBoxProps.testHook}
          searchButtonTestHook={defaultSearchBarBoxProps.searchButtonTestHook}
        />
      );
    };

    const currentSearchBarBox = getCurrentSearchBarBox();
    const currentSearchBarMenu = getCurrentSearchBarMenu();

    return (
      <div onKeyDown={handleKeyDown}>
        <StyledTooltip
          variant={defaultSearchBarMenuProps.variant}
          hasSameWidthModifier={hasSameWidthModifier}
          label={
            <StyledSearchBarBox
              variant={defaultSearchBarBoxProps.variant}
              onKeyDown={handleEnterClicked}
              onClick={onSearchBarClick}
            >
              {currentSearchBarBox}
            </StyledSearchBarBox>
          }
          placement="bottom-start"
          controller={{
            visible: isSearchMenuOpen,
            onClickOutside: onSearchBarClickOutside
          }}
          arrow={false}
          maxWidth={defaultSearchBarMenuProps.maxWidth}
          zIndex={999}
          offset={defaultSearchBarMenuProps.offset}
        >
          {currentSearchBarMenu}
        </StyledTooltip>
      </div>
    );
  }
);

const StyledTooltip = styled(Tooltip, {
  shouldForwardProp: (prop) => prop !== 'variant'
})<{ variant: string }>(
  ({ theme, variant }) => css`
    border-radius: ${theme.borderRadius.xSmall};

    .tippy-content {
      max-height: 100%;
      min-width: 700px;
      border-radius: ${variant === 'rounded' ? theme.borderRadius.xSmall : '0'};
    }
  `
);

const StyledSearchBarBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'variant'
})<{ variant: 'dark' | 'light' }>(({ theme, variant }) => {
  const isDarkVariant = variant === 'dark';

  return css`
    & .styled-input .input-area {
      position: relative;
      background-color: ${isDarkVariant ? theme.palette.natural.white : 'transparent'};

      input {
        padding: ${theme.spacing(16)};
        width: 100%;
        min-width: 320px;
        background-color: ${isDarkVariant
          ? theme.palette.natural.contrastDark
          : theme.palette.natural.white};
        border-radius: 23px;
        font-size: 13.5px;
        border: 1px solid ${isDarkVariant ? 'transparent' : theme.palette.natural.border};

        &:focus {
          box-shadow: none;
        }
      }
    }

    .search-buttons {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      height: 100%;

      .icon-button {
        color: ${theme.palette.text.primary};
      }

      .spinner-border {
        color: ${theme.palette.primary.main};
        position: absolute;
        right: 5px;
        top: 7px;
        width: 1.85rem;
        height: 1.85rem;
      }
    }
  `;
});
