// @ts-strict-ignore
import { useCallback, useState } from 'react';

import { editPatientModalTestSelectors } from 'tests/models/components/modal/edit-patient-modal/edit-patient-modal.selectors';

import { phoneTypeSupportsSMS } from 'utils/PhoneUtils';

import { Testable } from 'utils/TypeUtils';

import { PhoneTypeStrings } from 'models/PhoneNumberDetails';
import ScheduledProtocol, { ProtocolName, ProtocolType } from 'models/ScheduledProtocol';

import { IBaseInputProps } from 'views/Base/IBaseInputProps';

import StyledDropdown from './StyledDropdown';

import './ReportProtocolInput.scss';

interface IPatientPhoneNumberInput extends IBaseInputProps<ScheduledProtocol> {
  isInvite: boolean;
  phoneNumType: PhoneTypeStrings;
  isOptOutFlow: boolean;
  onProtocolChanged: (name: string, protocolType: ProtocolType) => void;
  openUp?: boolean;
}

const SELECT_PROTOCOL_LATER = -1;
const APP = 0;
const PHONE = 1;

export interface IProtocolOption extends Testable {
  label: string;
  value: number;
  classNames?: string;
  name: ProtocolName;
  type: ProtocolType;
  requiresMobile: boolean;
}

export const protocolOptions: IProtocolOption[] = [
  {
    label: `${ScheduledProtocol.getProtocolDisplayName(ProtocolType.mobile)}`,
    value: APP,
    name: ProtocolName.symptom,
    type: ProtocolType.mobile,
    requiresMobile: true,
    testHook: editPatientModalTestSelectors.reportProtocolSelectOption(ProtocolType.mobile)
  },
  {
    label: `${ScheduledProtocol.getProtocolDisplayName(ProtocolType.phone)}`,
    value: PHONE,
    name: ProtocolName.symptom,
    type: ProtocolType.phone,
    requiresMobile: false,
    testHook: editPatientModalTestSelectors.reportProtocolSelectOption(ProtocolType.phone)
  }
];

export const ReportProtocolInput = ({
  value: protocol,
  disabled,
  onProtocolChanged,
  error,
  phoneNumType,
  isInvite,
  isOptOutFlow,
  openUp
}: IPatientPhoneNumberInput) => {
  const [open, setIsOpen] = useState(false);

  const onClickedDropDown = () => setIsOpen(!open);

  const handleProtocolChanged = useCallback(
    (option: number) => {
      if (!phoneTypeSupportsSMS(phoneNumType) && option === APP) {
        return;
      }
      switch (option) {
        case APP:
          onProtocolChanged(null, ProtocolType.mobile);
          break;
        case PHONE:
          onProtocolChanged(null, ProtocolType.phone);
          break;
        case SELECT_PROTOCOL_LATER:
          onProtocolChanged(ProtocolName.chooseLater, null);
          break;
        default:
          throw new Error('unknown protocol option');
      }
    },
    [onProtocolChanged, phoneNumType]
  );

  const getProtocolOptions = (): Array<{
    label: string;
    value: any;
    classNames?: string;
    builder?: () => JSX.Element;
  }> => {
    const newProtocolOptions = protocolOptions.map((option) => ({
      ...option,
      classNames:
        option.requiresMobile && !phoneTypeSupportsSMS(phoneNumType)
          ? 'landline-option-disable'
          : ''
    }));
    if (isInvite) {
      // For invite flow we allow to select protocl later - (when activating)
      return [
        {
          value: SELECT_PROTOCOL_LATER,
          label: 'Choose Protocol Later'
        },
        ...newProtocolOptions
      ];
    }
    return newProtocolOptions;
  };

  const getProtocolFieldValue = () => {
    if (protocol && protocol.name !== ProtocolName.chooseLater) {
      return protocol.type === ProtocolType.phone ? PHONE : APP;
    }
    if (isInvite) {
      return SELECT_PROTOCOL_LATER;
    }
  };

  const protocolValue = getProtocolFieldValue();
  return (
    <StyledDropdown
      label="Report Protocol"
      onClick={onClickedDropDown}
      onChange={(option: number) => handleProtocolChanged(option)}
      value={protocolValue}
      disabled={disabled || isOptOutFlow}
      error={!isOptOutFlow && error && !protocol}
      options={getProtocolOptions()}
      noSelectedValueText={' '}
      forceSelectionText={protocol ? protocol.getShortString() : null}
      isOpen={open}
      openUp={openUp}
      testHook={editPatientModalTestSelectors.reportProtocolSelectButton}
    />
  );
};
