import { FC, ForwardedRef, ReactNode, forwardRef } from 'react';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ButtonProps, StyledText } from 'components/UIkit/atoms/Button/StyledButtons';
import { withSpacing, WithSpacingProps } from 'components/UIkit/theme/spacing';

interface InternalProps extends ButtonProps {
  children: ReactNode;
  size?: 'medium' | 'large';
  variant?: 'primary' | 'secondary';
  isActive?: boolean;
  forwardedRef?: ForwardedRef<HTMLButtonElement>;
}

type ExternalProps = Omit<InternalProps, 'forwardedRef'> & WithSpacingProps;

const Text: FC<InternalProps> = ({
  onClick,
  disabled,
  size = 'medium',
  variant = 'primary',
  isActive = false,
  testHook,
  children,
  forwardedRef
}) => (
  <StyledContainer
    onClick={onClick}
    disabled={disabled}
    variant={variant}
    isActive={isActive}
    ref={forwardedRef}
    data-test-hook={testHook}
  >
    <Typography variant={size === 'large' ? 'form-text' : 'button-medium-outline'}>
      {children}
    </Typography>
  </StyledContainer>
);

const StyledContainer = styled(StyledText)`
  padding: 0;
`;

const TextWithSpacing = withSpacing(Text);

export const TextButton: FC<ExternalProps> = forwardRef<HTMLButtonElement, ExternalProps>(
  (props, ref) => <TextWithSpacing {...props} forwardedRef={ref} />
);
