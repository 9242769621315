import { FC, ReactNode, useEffect } from 'react';

import MuiDialog from '@mui/material/Dialog';

import { AnalyticEventAction } from 'analytics/AnalyticEventAction';
import { Dialogs as DialogAnalyticsId, trackDialogAnalyticsEvent } from 'analytics/events/dialog';

import { Testable } from 'utils/TypeUtils';

import { DialogCloseReason } from './MessageDialog.constants';
import { closeReasonToAnaltiycsEventAction } from './MessageDialog.utils';

export interface DialogProps extends Testable {
  /** Enables analytics if provided. */
  id?: DialogAnalyticsId;
  isOpen: boolean;
  handleClose: (reason: DialogCloseReason) => void;
  children?: ReactNode;
}

/** Fully customizable dialog component. */
export const Dialog: FC<DialogProps> = ({ id, isOpen, handleClose, children, testHook }) => {
  const onClose = (reason: DialogCloseReason) => {
    if (reason === DialogCloseReason.BackdropClick) {
      // disabled.
      return;
    }
    trackDialogAnalyticsEvent(closeReasonToAnaltiycsEventAction(reason), id);
    handleClose && handleClose(reason);
  };

  // Tracks dialog open event if dialog has an id.
  useEffect(
    function dialogOpenEffect() {
      trackDialogAnalyticsEvent(AnalyticEventAction.Open, id);
    },
    [isOpen, id]
  );

  return (
    <MuiDialog
      open={isOpen}
      disableEscapeKeyDown
      hideBackdrop={false}
      onClose={(event, reason) => onClose(reason as DialogCloseReason)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-test-hook={testHook}
    >
      {children}
    </MuiDialog>
  );
};
