// @ts-strict-ignore
import { isString } from 'lodash/fp';

import { SearchedEmrPatient, SearchedPatient } from 'fetchers/PatientsFetcher';
import { GlobalSearchPatientsResponse } from 'fetchers/responses/patient.response';

import { parseDate, parseDateTimeForInputField } from 'utils/DateUtils';

import { CmStatus } from 'models/CmPatient';
import Patient, { PatientDxCode } from 'models/Patient';
import { PatientOptOut } from 'models/PatientOptOut';
import ScheduledProtocol, { ProtocolName } from 'models/ScheduledProtocol';
import UserModel from 'models/UserModel';

import ParseServerResponseService from './ParseServerResponseService';
import { UserParser } from './UserParser';

function parseProtocolInfo(protocolName: ProtocolName, code: number, info: any) {
  const parsedInfo: any = {};
  if (protocolName === ProtocolName.oralOncolytics) {
    Object.assign(parsedInfo, info);
    parsedInfo.fillFrequency = info.fill_frequency;
    parsedInfo.fillLocation = info.fill_location;
    parsedInfo.regimenId = info.regimen_id;
    parsedInfo.reportFrequency = info.report_frequency;
    parsedInfo.daysOfWeek = info.days_of_week;
  }

  if (protocolName === ProtocolName.drugSpecific) {
    parsedInfo.drugId = info.drugId;
    parsedInfo.reportProtocolTemplateId = info.reportProtocolTemplateId;
    parsedInfo.timeBetweenReports = info.time_between_reports;
  }

  if (info.end_type === ScheduledProtocol.END_TYPE_DATE) {
    parsedInfo.expirationDate = parseDate(info.expiration_date);
  }

  if (info.end_type === ScheduledProtocol.END_TYPE_OCCURRENCES) {
    parsedInfo.occurrences = info.occurrences;
  }

  if (info.end_type === ScheduledProtocol.END_TYPE_DURATION) {
    parsedInfo.duration = info.duration;
    parsedInfo.durationUnit = info.durationUnit;
  }

  parsedInfo.startingDate = parseDateTimeForInputField(info.starting_date);
  parsedInfo.endType = info.end_type;
  parsedInfo.timeBetweenReports = info.time_between_reports;

  switch (code) {
    case ScheduledProtocol.PROTOCOL_CODE_MULTIPLE_WEEKLY:
      parsedInfo.daysOfWeek = info.days_of_week;
      break;

    case ScheduledProtocol.PROTOCOL_CODE_LINEAR:
      parsedInfo.occurrences = info.occurrences;
      break;
  }
  return parsedInfo;
}

function parseScheduledProtocol(unparsedProtocol: any) {
  const scheduledProtocol = new ScheduledProtocol();
  scheduledProtocol.id = unparsedProtocol.id;
  const info = parseProtocolInfo(
    unparsedProtocol.name,
    unparsedProtocol.code,
    unparsedProtocol.info
  );
  scheduledProtocol.info = info;
  scheduledProtocol.name = unparsedProtocol.name;
  scheduledProtocol.type = unparsedProtocol.type;
  scheduledProtocol.code = unparsedProtocol.code;
  scheduledProtocol.lastReportIntervalAt = parseDate(unparsedProtocol.last_report_interval_at);
  scheduledProtocol.patientId = unparsedProtocol.patient_id;
  scheduledProtocol.timeZone = unparsedProtocol.time_zone;
  scheduledProtocol.operationMode = unparsedProtocol.operation_mode;
  scheduledProtocol.automationMode = unparsedProtocol.automation_mode;
  return scheduledProtocol;
}

export default class PatientParser {
  static parsePatientSearchResult(patientsSearchResults: GlobalSearchPatientsResponse): {
    patients: Patient[];
    hasMore: boolean;
  } {
    return {
      patients: patientsSearchResults.patients.map((searchedPatient) =>
        PatientParser.parseSearchedPatient(searchedPatient)
      ),
      hasMore: patientsSearchResults.hasMore
    };
  }

  static parseEmrSearchedPatient(patient: SearchedEmrPatient): Patient {
    const {
      mrn,
      firstName,
      lastName,
      emrPatientId,
      dob,
      phoneNumber,
      providerId,
      ssn,
      locationId,
      gender
    } = patient;
    const userModel = new UserModel(null, firstName, lastName);
    const patientModel = new Patient(userModel);
    patientModel.mrn = mrn;
    patientModel.emrPatientId = emrPatientId;
    patientModel.dateOfBirth = dob;
    patientModel.phone = phoneNumber;
    patientModel.providerId = providerId;
    patientModel.socialSecurity = ssn;
    patientModel.sex = gender;
    patientModel.location = locationId
      ? ParseServerResponseService.parseLocation({ id: patient.locationId })
      : null;

    return patientModel;
  }

  static parseSearchedPatient = (patient: SearchedPatient): Patient => {
    const { mrn, firstName, lastName, patientId, emrPatientId, dob, userId } = patient;
    const userModel = new UserModel(userId, firstName, lastName);
    const patientModel = new Patient(userModel);
    patientModel.mrn = mrn;
    patientModel.id = patientId;
    patientModel.emrPatientId = emrPatientId;
    patientModel.dateOfBirth = dob;

    return patientModel;
  };

  static parsePatient = (patient: any): Patient => {
    const userModel = UserParser.parseUser(patient.user);
    const patientModel = new Patient(userModel);

    patientModel.id = patient.id;
    patientModel.lastReportAckTime = patient.last_report_ack_time;
    patientModel.lastReportNameRequested = patient.last_report_name_requested;
    patientModel.lastReportTime = patient.last_report_time;
    patientModel.phone = patient.user.phone || patient.user.mobile_phone;
    patientModel.lastSmsRequestReportTime = parseDate(patient.last_sms_request_report_time);
    patientModel.protocolOverdueStartTime = parseDate(patient.protocol_overdue_start_time);
    patientModel.cmStatus = new CmStatus(patient.cmStatus);
    patientModel.lastAutomatedRequestReportTime = parseDate(
      patient.last_automated_request_report_time
    );
    patientModel.requestCallAttempts = patient.request_call_attempts;
    patientModel.lastSnoozeTime = parseDate(patient.last_snooze_time);
    patientModel.pushToken = patient.push_token;
    patientModel.tags = patient.tagIds;
    patientModel.mrn = patient.mrn;
    patientModel.status = patient.status;
    patientModel.nurse = patient.nurse;
    patientModel.socialSecurity = patient.social_security;
    patientModel.locale = patient.locale;
    patientModel.createdAt = patient.created_at;
    patientModel.updatedAt = patient.updated_at;
    patientModel.callbackReason = patient.callback_reason;
    patientModel.lastPAMessage = patient.last_pa_message;
    patientModel.wasCovidDismissed = patient.was_covid_dismissed;
    patientModel.covidRequestsSent = patient.covid_requests_sent;
    // TODO: change || when no more v1 left (snake case)
    patientModel.remoteMonitoringConsent = parseDate(
      patient.remote_monitoring_consent || patient.remoteMonitoringConsent
    );
    patientModel.joinServiceDate = parseDate(patient.join_service_date);
    patientModel.sourceId = patient.source_id;
    patientModel.enrollmentStatus = patient.enrollment_status;
    patientModel.lastTicketCreatedAt = patient.last_ticket_created_at;
    patientModel.isLagging = patient.is_lagging;
    patientModel.isDeceased = patient.is_deceased || patient.isDeceased; //work-queue v2 -> isDeceased
    patientModel.location = patient.location
      ? ParseServerResponseService.parseLocation(patient.location)
      : null;
    // TODO: change || when no more v1 left (snake case)
    patientModel.sex = patient.birth_sex || patient.sex;
    patientModel.dateOfBirth = patient.date_of_birth || patient.dateOfBirth;

    patientModel.optOut = patient.patientOptOut
      ? PatientOptOut.fromJson(patient.patientOptOut)
      : null;
    patientModel.activatedAt = patient.activated_at;
    patientModel.financialAssistance = patient.financial_assistance;
    patientModel.emrPatientId = patient.emr_patient_id;
    // TODO: change || when no more v1 left (snake case)
    patientModel.providerId = patient.provider_id || patient.providerId;
    if (patient.patients_to_departments && Array.isArray(patient.patients_to_departments)) {
      patientModel.departmentIds = patient.patients_to_departments.map(
        (item: any) => item.department_id
      );
    }

    const unparsedContacts = Array.isArray(patient.patientContacts) ? patient.patientContacts : [];
    patientModel.contacts = unparsedContacts.map((contact: any) =>
      ParseServerResponseService.parsePatientContact(contact)
    );

    if (Array.isArray(patient.questionnairesAnswers)) {
      patientModel.questionnairesAnswers = patient.questionnairesAnswers.map(
        ParseServerResponseService.parseReport
      );
    }
    if (Array.isArray(patient.scheduledProtocols)) {
      patientModel.scheduledProtocols = patient.scheduledProtocols.map(parseScheduledProtocol);
    }

    if (patient.dxCodes?.length) {
      const codes: PatientDxCode[] = patient.dxCodes.map(
        (codeItem: PatientDxCode | string): PatientDxCode => {
          if (isString(codeItem)) {
            return {
              code: codeItem
            };
          }

          return {
            code: codeItem.code,
            text: codeItem.text
          };
        }
      );
      patientModel.dxCodesMap.setItems(codes);
    }
    return patientModel;
  };
}
