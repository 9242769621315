// @ts-strict-ignore
import { FC, useEffect } from 'react';

import { observer } from 'mobx-react';

import { useFormContext } from 'react-hook-form';

import { useStores } from 'mobx/hooks/useStores';

import { WEEK_IN_MILLISECONDS } from 'utils/DateUtils';

import {
  OralFillLocations,
  OralProtocolReportFrequencies,
  ProtocolType
} from 'models/ScheduledProtocol';

import { SelectOption } from 'models/SelectOption';

import EndProtocolOptions from 'views/Widgets/EndProtocolOptions';
import ProtocolIntervalFields from 'views/Widgets/ProtocolIntervalFields';
import ProtocolTimesMessageDivider from 'views/Widgets/ProtocolTimesMessageDivider';
import RegimenSummaryField from 'views/Widgets/RegimenSummaryField';
import { RHFStyledInput } from 'views/Widgets/StyledInput';

import { FormAutocomplete } from 'components/UIkit/atoms/Dropdown';

import './OralProtocolFields.scss';

interface OralProtocolFieldsProps {
  type: ProtocolType;
  openRegimenModal: () => void;
  isRegimenModalOpen: boolean;
}

export const fillLocationOptions = [
  { label: 'In-House', value: OralFillLocations.IN_HOUSE },
  { label: 'External Pharmacy', value: OralFillLocations.EXTERNAL }
];

export const frequencyOptions = [
  { label: 'Standard Frequency (Weekly)', value: OralProtocolReportFrequencies.REGULAR },
  { label: 'Reduced Frequency (Once Per Cycle)', value: OralProtocolReportFrequencies.LOW }
];

const OralProtocolFields: FC<OralProtocolFieldsProps> = (props: OralProtocolFieldsProps) => {
  const methods = useFormContext();
  const { constantsStore } = useStores();
  const [regimenId, regimen, selectedStartHour, selectedStartDate, weekdays] = methods.watch([
    'regimenId',
    'regimen',
    'selectedStartHour',
    'selectedStartDate',
    'weekdays'
  ]);

  useEffect(() => {
    methods.register('regimen');
  });

  const regimenTemplate = constantsStore.regimens.get(regimenId?.value);
  const finalRegimen = regimen || regimenTemplate;

  return (
    <>
      <div className="oral-protocol-fields">
        <ProtocolIntervalFields showDaySelector forceWeekly forceSingleDaySelection />
        <ProtocolTimesMessageDivider
          weekdays={weekdays}
          startHour={selectedStartHour.value}
          startDate={selectedStartDate}
          isPhoneProtocol={props.type === ProtocolType.phone}
          intervalFrequency={WEEK_IN_MILLISECONDS}
        />
        <div className="section">
          <RHFStyledInput
            type="date"
            label="Activation of Protocol"
            isRequired
            register={methods.register}
            error={Boolean(methods.formState.errors.selectedStartDate)}
            name="selectedStartDate"
          />
        </div>
        <div className="section">
          <EndProtocolOptions minEndDate={selectedStartDate} />
        </div>
        <div className="section">
          <FormAutocomplete
            label="Regimen"
            isRequired
            onChange={(option: SelectOption<number>) => {
              if (option && option?.value !== regimenTemplate?.id) {
                const template = constantsStore.regimens.get(option.value);
                methods.setValue('regimen', template);
              }
            }}
            options={constantsStore.regimenTemplatesForSelect}
            name="regimenId"
          />
        </div>
        {regimenId && (
          <>
            <div className="section">
              <RegimenSummaryField
                isCustom={Boolean(finalRegimen?.isCustom)}
                regimen={finalRegimen}
                onCustomizeClicked={props.openRegimenModal}
              />
            </div>
            <div className="section">
              <RHFStyledInput
                type="textarea"
                label="Additional Regimen Instructions (Optional)"
                register={methods.register}
                name="notes"
                maxLength={500}
                error={Boolean(methods.formState.errors.notes)}
              />
            </div>
          </>
        )}
        <div className="section">
          <FormAutocomplete
            sortAlphabetically={false}
            isRequired
            label="Fill Location"
            options={fillLocationOptions}
            name="fillLocation"
          />
          <RHFStyledInput
            type="number"
            min={1}
            max={120}
            label="Fill Frequency (Days)"
            error={Boolean(methods.formState.errors.fillFrequency)}
            isRequired
            register={methods.register}
            name="fillFrequency"
          />
        </div>
      </div>
    </>
  );
};

export default observer(OralProtocolFields);
