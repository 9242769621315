import { FC, MouseEvent } from 'react';

import { Dialogs } from 'analytics/events/dialog';

import { MessageDialog } from 'components/UIkit/atoms/Dialog';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: (event: MouseEvent<HTMLButtonElement>) => void;
  selectedTableRowsCount: number;
}

export const ClearCmTableSelectionPopup: FC<Props> = ({
  isOpen,
  onClose,
  onSave,
  selectedTableRowsCount
}) => (
  <MessageDialog
    id={Dialogs.ClearCmTableSelection}
    isOpen={isOpen}
    handleClose={onClose}
    title="Clear Selection?"
    primaryActionProps={{ text: 'Unselect All', onClick: onSave }}
    secondaryActionProps={{ text: 'Cancel', onClick: onClose }}
  >
    Filtering the table will clear your selection. Are you sure you want to clear
    {selectedTableRowsCount} selected patients and continue filtering?
  </MessageDialog>
);
