// @ts-strict-ignore
import { Component, createRef, MouseEvent, ReactNode, RefObject } from 'react';

import classNames from 'classnames';
import { noop } from 'lodash/fp';

import { Testable } from 'utils/TypeUtils';

import Icon from 'components/Icons/Icon';
import { Tooltip } from 'components/Tooltip';

import './StyledDropdown.scss';

export interface IStyledDropdownProps extends Testable {
  value: any;
  options: Array<{
    label: string | ReactNode;
    value?: any;
    disableSelect?: boolean;
    isDisabled?: Boolean;
    classNames?: string;
    builder?: () => JSX.Element;
    testHook?: string;
  }>;
  onChange: (value: any, clickEvent: MouseEvent<HTMLDivElement>) => void;
  onClick?: () => void;
  isOpen?: boolean;
  label?: string;
  inlineLabel?: boolean;
  labelTooltip?: string;
  error?: boolean;
  errorMessage?: string;
  isRequired?: boolean;
  rounded?: boolean;
  disabled?: boolean;
  noSelectedValueText?: string;
  forceSelectionText?: string;
  smallHeadHeight?: boolean;
  openUp?: boolean;
}

interface IStyledDropdownState {
  isOpen: boolean;
}
export default class StyledDropdownProps extends Component<
  IStyledDropdownProps,
  IStyledDropdownState
> {
  dropdownRef: RefObject<HTMLDivElement>;

  constructor(props: IStyledDropdownProps) {
    super(props);
    this.dropdownRef = createRef();
    this.state = {
      isOpen: false
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event: Event) => {
    if (!this.dropdownRef.current.contains(event.target as Node) && this.isOpen()) {
      if (this.props.onClick) {
        this.props.onClick();
      } else {
        this.toggleDropDown();
      }
    }
  };

  handleOnclick = () => {
    if (this.props.disabled) {
      return;
    }

    if (!this.props.onClick) {
      this.toggleDropDown();
    } else {
      this.props.onClick();
    }
  };

  isOpen = () => {
    return this.props.isOpen || this.state.isOpen;
  };
  toggleDropDown = () => {
    this.setState((prevState) => {
      return { isOpen: !prevState.isOpen };
    });
  };

  render() {
    const selectedOption = this.props.options.find((option) => option.value === this.props.value);
    let mainText = selectedOption ? selectedOption.label : this.props.noSelectedValueText;

    if (this.props.forceSelectionText) {
      mainText = this.props.forceSelectionText;
    }

    const inlileLabel =
      this.props.label && this.props.inlineLabel ? <b>{this.props.label} </b> : null;
    return (
      <div
        className={`styled-dropdown ${this.isOpen() ? 'open' : 'closed'} ${
          this.props.error ? 'error' : ''
        } ${this.props.disabled ? 'disabled' : ''}`}
        onClick={this.handleOnclick}
        ref={this.dropdownRef}
        data-test-hook={this.props.testHook}
      >
        {this.props.label && !this.props.inlineLabel && (
          <div className={classNames('dropdown-label', { error: this.props.error })}>
            {`${this.props.isRequired ? '* ' : ''}${this.props.label}`}{' '}
            {this.props.labelTooltip && (
              <Tooltip label={<Icon.Info />}>
                <div className="p-3">{this.props.labelTooltip}</div>
              </Tooltip>
            )}
          </div>
        )}
        <div
          className={`dropdown-head-container ${
            this.props.rounded || !this.props.label ? 'rounded-borders' : ''
          } ${this.props.smallHeadHeight ? 'small-dropdown-head' : ''}`}
        >
          {this.props.error ? (
            <p className="error-text">{this.props.errorMessage}</p>
          ) : (
            <p
              className={`${selectedOption ? '' : 'placeholder'}`}
              title={mainText as string}
              data-test-hook={
                typeof mainText === 'string' ? mainText.split(' ').join('-').toLowerCase() : ''
              }
            >
              {inlileLabel}
              {mainText}
            </p>
          )}
        </div>

        <div className={classNames('dropdown-container', { 'open-up': this.props.openUp })}>
          {this.props.options.map((option) => (
            <div
              key={option.value}
              className={classNames('dropdown-option', option.classNames, {
                selected: this.props.value === option.value && !option.disableSelect,
                'disabled-option': option.isDisabled
              })}
              onClick={option.isDisabled ? noop : (e) => this.props.onChange(option.value, e)}
              data-test-hook={option?.testHook}
            >
              <Icon.CheckMark className="option-checkmark" />
              {option.builder ? option.builder() : option.label}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
