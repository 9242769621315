import { FC } from 'react';

import { useStores } from 'mobx/hooks/useStores';

import { getPatientIdFromUrl } from 'utils/urlUtils';

import { showToast } from 'utils/UserMessageUtils';

import { API_HOST } from 'constants/config';

import Icon from 'components/Icons/Icon';

const BASE_PDF_URL = `${API_HOST}/debug/patient/mdm-messages`;

interface Props {
  onCloseQaActions: () => void;
  toggleEmrRowModal: () => void;
  toggleCmRowModal: () => void;
  toggleDxModal: () => void;
  toggleLocationModal: () => void;
  toggleMobileTokenModal: () => void;
  toggleWebEProTokenModal: () => void;
  toggleEnrollmentTokenModal: () => void;
  toggleAdjustPatientTimelineModal: () => void;
  toggleProviderModal: () => void;
  toggleAddTreatmentModal: () => void;
  toggleDeceasedModal: () => void;
}
const QaActions: FC<Props> = ({
  onCloseQaActions,
  toggleEmrRowModal,
  toggleCmRowModal,
  toggleDxModal,
  toggleProviderModal,
  toggleLocationModal,
  toggleMobileTokenModal,
  toggleWebEProTokenModal,
  toggleEnrollmentTokenModal,
  toggleAdjustPatientTimelineModal,
  toggleAddTreatmentModal,
  toggleDeceasedModal
}) => {
  const { qaStore, departmentsStore, patientPageStore } = useStores();

  const openPdfDocsPage = () => {
    // if in patient page, show patient pdfs, otherwise show institution pdfs
    const patientId = getPatientIdFromUrl();
    const patient = patientId ? patientPageStore.patient : null;
    const pdfUrlQueryParams = patient
      ? `mrn=${patient.mrn}`
      : `institution=${departmentsStore.rootDepartmentId}`;
    const pdfUrl = `${BASE_PDF_URL}?${pdfUrlQueryParams}`;
    window.open(pdfUrl, '_blank', 'noopener,noreferrer');
  };

  const handleConfirm = (action: 'reset' | 'clear') => {
    action === 'reset' ? qaStore.resetInstitution() : qaStore.clearPatients();
  };

  const handleActionClicked = (action: 'reset' | 'clear') => () => {
    const confirm = window.confirm('Are you sure?');

    if (confirm) {
      handleConfirm(action);
    }
    onCloseQaActions();
  };

  const handleBackClicked = (event: any) => {
    event.stopPropagation();
    onCloseQaActions();
  };

  const generateNotifications = () => {
    qaStore.generateNotifications();
  };

  const handleAdjustAutoProtocol = () => {
    const patientId = getPatientIdFromUrl();
    if (patientId) {
      qaStore.adjustPatientAutoProtocol(patientId);
    } else {
      showToast({
        message: 'Must be on patient page'
      });
    }
  };

  return (
    <>
      <div className="header-dropdown-item" onClick={handleBackClicked}>
        <span className="flex-grow-1">Main Menu</span>
        <Icon.DoubleArrow />
      </div>

      <div className="header-dropdown-item" onClick={toggleEmrRowModal}>
        Add EMR Row
      </div>

      <div className="header-dropdown-item" onClick={toggleCmRowModal}>
        Add CM Row
      </div>

      <div className="header-dropdown-item" onClick={toggleDxModal}>
        Add Diagnosis
      </div>

      <div className="header-dropdown-item" onClick={toggleLocationModal}>
        Add Location
      </div>

      <div className="header-dropdown-item" onClick={toggleProviderModal}>
        Add Provider
      </div>

      <div className="header-dropdown-item" onClick={toggleDeceasedModal}>
        Patient Deceased Status
      </div>

      <div className="header-dropdown-item" onClick={toggleMobileTokenModal}>
        Patient Mobile Token
      </div>

      <div className="header-dropdown-item" onClick={toggleWebEProTokenModal}>
        Patient Web EPro Token
      </div>

      <div className="header-dropdown-item" onClick={toggleEnrollmentTokenModal}>
        Patient Enrollment Token
      </div>

      <div className="header-dropdown-item" onClick={openPdfDocsPage}>
        Patient/Institution PDFs
      </div>

      <div className="header-dropdown-item" onClick={toggleAdjustPatientTimelineModal}>
        Adjust Patient Timeline
      </div>

      <div className="header-dropdown-item" onClick={handleActionClicked('reset')}>
        Reset Institution
      </div>

      <div className="header-dropdown-item" onClick={handleActionClicked('clear')}>
        Clear Patients
      </div>

      <div className="header-dropdown-item" onClick={generateNotifications}>
        Generate Notifications
      </div>

      <div className="header-dropdown-item" onClick={toggleAddTreatmentModal}>
        Add Treatment
      </div>

      <div className="header-dropdown-item" onClick={handleAdjustAutoProtocol}>
        Adjust auto protocol
      </div>
    </>
  );
};

export default QaActions;
