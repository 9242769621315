import { ProtocolType } from 'models/ScheduledProtocol';

export const editPatientModalTestSelectors = {
  modal: 'edit-patient-modal',
  submitButton: 'edit-patient-modal-submit-button',
  reportProtocolSelectButton: 'edit-patient-modal-report-protocol-select-button',
  reportProtocolSelectOption: (option: ProtocolType) =>
    `edit-patient-modal-report-protocol-select-button-${option}`,
  switchProtocolPopup: 'edit-patient-modal-switch-protocol-popup'
};
