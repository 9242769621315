import { FC } from 'react';

import classNames from 'classnames';

import { useHistory } from 'react-router-dom';

import { SearchedPatient } from 'fetchers/PatientsFetcher';

import { formatDate } from 'utils/DateUtils';

import { Highlighter } from 'components/Tooltip/Highlighter';

interface Props {
  patient: SearchedPatient;
  onPatientClicked: (patient: SearchedPatient) => void;
  isActive: boolean;
  isAnimationDisabled: boolean;
  searchTerm?: string;
}

// Note: uses onMouseDown since it happens before parent onBlur (which will hide the content)
export const SearchedPatientRow: FC<Props> = ({
  patient,
  onPatientClicked,
  isActive,
  isAnimationDisabled,
  searchTerm = ''
}) => {
  const history = useHistory();

  const classes = classNames('patient px-3 py-2', {
    active: isActive,
    current: history.location.pathname.includes(`/patient/${patient.patientId}`),
    'no-animation': isAnimationDisabled
  });

  return (
    <div
      className={classes}
      onMouseDown={() => onPatientClicked(patient)}
      data-test-hook={`searched-patient-row-${patient.patientId}`}
    >
      <Highlighter
        searchValue={searchTerm}
        textToHighlight={`${patient.lastName}, ${patient.firstName}`}
        className="patient-name"
      />

      <div className="patient-info">
        <div className="dob">
          <span className="label">DOB:</span>{' '}
          <span className="text-value">{patient.dob ? formatDate(patient.dob, 'L') : '-'}</span>
        </div>

        <div className="mrn">
          <span className="label">MRN:</span>{' '}
          <span className="text-value">{patient.mrn || '-'}</span>
        </div>
      </div>
    </div>
  );
};
