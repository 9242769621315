import { FC, forwardRef, ReactNode } from 'react';

import { css, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Checkbox, CheckboxProps } from 'components/UIkit/atoms/Checkbox/Checkbox';
import { getContainerColor } from 'components/UIkit/atoms/Checkbox/utils';

interface Props extends CheckboxProps {
  label: ReactNode;
  subText: string;
}

export const SubtextLabeledCheckbox: FC<Props> = forwardRef(
  (
    { id, disabled = false, error = false, onKeyDown, name, checked, onChange, label, subText },
    ref
  ) => (
    <StyledContainer container alignItems="center" disabled={disabled} error={error}>
      <Checkbox
        id={id}
        disabled={disabled}
        error={error}
        onKeyDown={onKeyDown}
        checked={checked}
        onChange={onChange}
        ref={ref}
        name={name}
      />

      <StyledTextContainer container direction="column" disabled={disabled}>
        <Typography variant="h4" htmlFor={id} component="label">
          {label}
        </Typography>

        <Typography variant="form-text" htmlFor={id} component="label">
          {subText}
        </Typography>
      </StyledTextContainer>
    </StyledContainer>
  )
);

const StyledContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'error' && prop !== 'disabled'
})<{ disabled: boolean; error: boolean }>(
  ({ theme, disabled, error }) => css`
    flex-wrap: nowrap;
    gap: ${theme.spacing(8)};
    color: ${getContainerColor(theme, disabled, error)};
  `
);

const StyledTextContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'disabled'
})<{ disabled: boolean }>(
  ({ disabled }) => css`
    label {
      cursor: ${disabled ? 'initial' : 'pointer'};
    }
  `
);
