import { FC, ForwardedRef, forwardRef, ReactNode } from 'react';

import { css, Typography } from '@mui/material';

import { styled } from '@mui/material/styles';

import { ButtonProps, StyledOutlined } from 'components/UIkit/atoms/Button/StyledButtons';
import { withSpacing, WithSpacingProps } from 'components/UIkit/theme/spacing';

interface InternalProps extends ButtonProps {
  children: ReactNode;
  icon: ReactNode;
  variant?: 'primary' | 'secondary';
  forwardedRef?: ForwardedRef<HTMLButtonElement>;
}

type ExternalProps = Omit<InternalProps, 'forwardedRef'> & WithSpacingProps;

const OutlinedIcon: FC<InternalProps> = ({
  onClick,
  disabled,
  icon,
  variant = 'primary',
  testHook,
  children,
  forwardedRef
}) => (
  <Container
    onClick={onClick}
    disabled={disabled}
    ref={forwardedRef}
    data-test-hook={testHook}
    variant={variant}
  >
    {icon}
    <Typography variant="button-medium-outline">{children}</Typography>
  </Container>
);

const Container = styled(StyledOutlined)<{ variant: 'primary' | 'secondary' }>(
  ({ theme, variant }) => css`
    box-shadow: ${theme.boxShadow.common};
    display: flex;
    align-items: center;

    ${variant === 'secondary' &&
    css`
      box-shadow: none;
    `}

    svg {
      margin-right: ${theme.spacing(8)};
      color: ${theme.palette.primary.main};
    }

    &:disabled {
      box-shadow: none;

      svg {
        color: ${theme.palette.text.disabled};
      }
    }
  `
);

const OutlinedIconWithSpacing = withSpacing(OutlinedIcon);

export const OutlinedIconButton: FC<ExternalProps> = forwardRef<HTMLButtonElement, ExternalProps>(
  (props, ref) => <OutlinedIconWithSpacing {...props} forwardedRef={ref} />
);
