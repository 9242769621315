// @ts-strict-ignore
import { makeAutoObservable, toJS } from 'mobx';
import numWords from 'num-words';

export enum StomachStatus {
  Full = 'full',
  Empty = 'empty'
}

export default class RegimenTemplate {
  id: number;
  name: string;
  timesPerDay: number;
  daysOff: number;
  daysOn: number;
  stomachStatus: StomachStatus;
  foodLimitations: string[];
  minHoursBetweenDoses: number;
  isCustom: boolean;

  constructor(regimen: any) {
    makeAutoObservable(this);
    Object.assign(this, regimen);
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      timesPerDay: this.timesPerDay,
      daysOff: this.daysOff,
      daysOn: this.daysOn,
      stomachStatus: this.stomachStatus,
      foodLimitations: toJS(this.foodLimitations),
      minHoursBetweenDoses: this.minHoursBetweenDoses,
      isCustom: this.isCustom
    };
  }

  static timesText(timesPerDay: number) {
    if (timesPerDay === 1) {
      return 'once';
    }
    if (timesPerDay === 2) {
      return 'twice';
    }
    return numWords(timesPerDay) + ' times';
  }
}
