export const sharedTestSelectors = {
  lightweightPathwayQuestionContainer: (questionId: string) =>
    `lightweight-pathway-question-${questionId}`,
  lightweightPathwaySingleQuestionOption: (optionId: string) =>
    `lightweight-pathway-single-question-answer-${optionId}`,
  lightweightPathwayMultipleQuestionOption: (optionId: string) =>
    `lightweight-pathway-multiple-question-answer-${optionId}`,
  lightweightPathwayTextQuestionInput: (questionId: string) =>
    `lightweight-pathway-text-question-input-${questionId}`
};
