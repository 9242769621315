import { ChangeEvent, FC, MouseEvent } from 'react';

import { Box, css, styled } from '@mui/material';

import { createPortal } from 'react-dom';

import { Testable } from 'utils/TypeUtils';

import { PasteFromClipboardButton } from 'views/Pages/Invitation/PasteFromClipboardButton';

import { SearchCircle } from 'views/Widgets/icons/SearchCircle';

import StyledInput from 'views/Widgets/StyledInput';

import { Backdrop } from 'components/Backdrop';

import IconButton from 'components/Buttons/IconButton';
import Icon from 'components/Icons/Icon';
import { Tooltip } from 'components/Tooltip';
import { FilledButton } from 'components/UIkit/atoms/Button';
import { Text } from 'components/UIkit/atoms/Text';

interface SearchBarProps extends Testable {
  searchValue: string;
  onSearchChanged: (searchTerm: string) => void;
  onGoClicked: (isKeyboardSource?: boolean) => void;
  onPasteFromClipboard?: (searchTerm: string) => void;
  isLoading: boolean;
  toggleAdvancedSearch: () => void;
  isAdvancedSearchFeatureIntroOpen: boolean;
  updateAdvancedSearchIntroFinished: () => void;
  clearSearch: (event: MouseEvent<HTMLButtonElement>) => void;
  placeholder: string;
  hasPasteMrnOption: boolean;
  searchButtonTestHook?: string;
}

export const BasicSearchBox: FC<SearchBarProps> = ({
  searchValue,
  onSearchChanged,
  onGoClicked,
  isLoading,
  toggleAdvancedSearch,
  clearSearch,
  isAdvancedSearchFeatureIntroOpen,
  updateAdvancedSearchIntroFinished,
  placeholder,
  hasPasteMrnOption,
  onPasteFromClipboard,
  testHook,
  searchButtonTestHook
}) => {
  const handlePasteFromClipboard = (clipboardText: string) => {
    if (clipboardText === searchValue || !clipboardText) {
      return;
    }

    onPasteFromClipboard && onPasteFromClipboard(clipboardText);
  };

  return (
    <StyledInput
      placeholder={placeholder}
      type="text"
      value={searchValue}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChanged(e.target.value)}
      testHook={testHook || 'global-patients-search-input'}
    >
      <div className="search-buttons p-2 mr-1" onClick={(event) => event.stopPropagation()}>
        <Box display="flex" alignItems="center">
          {searchValue.length > 0 ? (
            <>
              {hasPasteMrnOption && (
                <Box mr={20}>
                  <PasteFromClipboardButton onClick={handlePasteFromClipboard} />
                </Box>
              )}

              <IconButton onClick={clearSearch}>
                <Icon.XThin />
              </IconButton>
            </>
          ) : (
            <Tooltip
              label={
                <Box display="flex" alignItems="center">
                  {hasPasteMrnOption && (
                    <Box mr={20}>
                      <PasteFromClipboardButton onClick={handlePasteFromClipboard} />
                    </Box>
                  )}

                  <StyledSettingsIcon
                    onClick={(event) => {
                      event.stopPropagation();
                      toggleAdvancedSearch();
                    }}
                  />

                  {isAdvancedSearchFeatureIntroOpen &&
                    createPortal(
                      <StyledFixedSettingsIcon>
                        <Icon.Settings />
                      </StyledFixedSettingsIcon>,
                      document.body
                    )}
                </Box>
              }
              controller={{
                visible: isAdvancedSearchFeatureIntroOpen ? true : false,
                onClickOutside: () => null
              }}
              maxWidth={566}
              offset={[0, 20]}
            >
              {isAdvancedSearchFeatureIntroOpen && (
                <>
                  <Box p={20} display="flex" flexDirection="column">
                    <Text variant="body1" display="inline-block" mb={8}>
                      Introducing Advanced Patient Search
                    </Text>

                    <Text variant="body2" display="inline-block" mb={20}>
                      It’s now much easier to search for patients across Canopy. Just press the new
                      Advanced Search button, and you’ll be able to find patients via name, date of
                      birth, and MRN, simultaneously.
                    </Text>

                    <Box alignSelf="flex-end">
                      <FilledButton onClick={updateAdvancedSearchIntroFinished}>
                        Got It
                      </FilledButton>
                    </Box>
                  </Box>

                  {createPortal(
                    <Backdrop isOpen zIndex={1002} isInvisible={false} />,
                    document.body
                  )}
                </>
              )}
            </Tooltip>
          )}
        </Box>

        <StyledIconButton onClick={() => onGoClicked()} testHook={searchButtonTestHook}>
          {isLoading && (
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )}

          <SearchCircle />
        </StyledIconButton>
      </div>
    </StyledInput>
  );
};

const StyledSettingsIcon = styled(Icon.Settings)(
  ({ theme }) => css`
    cursor: pointer;

    &:hover {
      color: ${theme.palette.primary.main};
    }
  `
);

const StyledFixedSettingsIcon = styled(Box)(
  ({ theme }) => css`
    cursor: pointer;
    padding: ${theme.spacing(4)};
    position: absolute;
    top: 16px;
    left: 407px;
    border-radius: ${theme.borderRadius.large};
    background-color: ${theme.palette.natural.white};
    z-index: 1050;
  `
);

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    margin-left: ${theme.spacing(8)};
  `
);
