import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { OptionProps } from 'react-select';
import { InputActionMeta } from 'react-select/dist/declarations/src/types';

import { formatDate } from 'utils/DateUtils';

import { FormAsyncBaseSelect } from 'components/UIkit/atoms/Dropdown/Select/Autocomplete/AsyncAutocomplete';
import { StyledMenuOption } from 'components/UIkit/atoms/Dropdown/Select/ReactSelectOptionComponent';
import {
  AsyncBaseSelectProps,
  SelectComponents
} from 'components/UIkit/atoms/Dropdown/Select.shared';
import { AsyncFormProps } from 'components/UIkit/atoms/Dropdown/withFormAsyncSelect';

interface Props extends Omit<AsyncBaseSelectProps, 'value'>, AsyncFormProps {
  openMenuOnClick?: boolean;
  menuIsOpen?: boolean;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
}

export const FormSpecificPatientsAsyncMultiAutocomplete: FC<Props> = (props) => {
  const components: SelectComponents = {
    IndicatorsContainer: () => null,
    Option: CustomOption
  };

  return <FormAsyncBaseSelect isMulti components={components} {...props} />;
};

export interface SpecificPatientOption {
  value: number;
  label: string;
  dob: string;
  mrn: string;
  emrPatientId?: string | null;
}

const CustomOption = (props: OptionProps<SpecificPatientOption>) => {
  return (
    <StyledMenuOption {...props}>
      <Box display="flex" width="100%">
        <Typography variant="h4" flex={1}>
          {props.data.label}
        </Typography>

        <Typography variant="h4" flex={1}>
          MRN: {props.data.mrn}
        </Typography>

        <Typography variant="h4" flex={1}>
          DOB: {formatDate(props.data.dob, 'L')}
        </Typography>
      </Box>
    </StyledMenuOption>
  );
};
