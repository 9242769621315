// @ts-strict-ignore
import { FC, useState } from 'react';

import { Box } from '@mui/material';
import { Dialogs } from 'analytics/events/dialog';

import { useForm } from 'react-hook-form';

import { useStores } from 'mobx/hooks/useStores';

import { RHFStyledInput } from 'views/Widgets/StyledInput';

import { MessageDialog } from 'components/UIkit/atoms/Dialog';

import { isAddOptionValue } from 'components/UIkit/atoms/Dropdown';
import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

// TODO: check length
const MAX_ROLE_NAME_LENGTH = 255;

export interface EditCreateRoleFields {
  name: string;
}

interface Props {
  roleId?: number;
  onCancel: () => void;
  onSave: (id: number, body: EditCreateRoleFields) => void;
  onDelete: (id: number) => void;
  onCreate: (body: any) => void;
  isOpen: boolean;
  resetDataAfterClose: () => void;
}

const EditCreateRoleModal: FC<Props> = ({
  roleId,
  onCancel,
  onSave,
  onDelete,
  onCreate,
  isOpen,
  resetDataAfterClose
}) => {
  const { constantsStore } = useStores();
  const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);
  const isCreate = isAddOptionValue(roleId);
  const roleName =
    roleId && !isCreate ? constantsStore.getRoleByIdWithAllAndNoRoles(roleId)?.name : null;
  const methods = useForm<EditCreateRoleFields>();

  const { handleSubmit, register, formState } = methods;
  const { errors } = formState;

  const onSaveClicked = (values: EditCreateRoleFields) => {
    !isCreate ? onSave(roleId, values) : onCreate(values);
  };

  return (
    <>
      {isDeleteWarningOpen && (
        <MessageDialog
          id={Dialogs.DeleteRole}
          isOpen
          title="Delete this role?"
          handleClose={() => setIsDeleteWarningOpen(false)}
          primaryActionProps={{
            text: 'Delete',
            onClick: () => {
              onDelete(roleId);
              setIsDeleteWarningOpen(false);
            }
          }}
          secondaryActionProps={{ text: 'Cancel', onClick: () => setIsDeleteWarningOpen(false) }}
        >
          "{roleName}" will be deleted. It will remain visible among the Task Role filter options if
          there are any unresolved tasks currently assigned to it.
        </MessageDialog>
      )}

      <FormModal
        defaultValues={{ name: roleName }}
        methods={methods}
        isOpen={isOpen}
        title={isCreate ? 'Add Role' : 'Edit Role'}
        confirmActions={[{ onClick: handleSubmit(onSaveClicked), text: 'Save', disabled: false }]}
        closeAction={{ onClick: onCancel, disabled: false }}
        secondaryAction={{
          isVisible: !isCreate,
          type: 'button',
          onClick: () => setIsDeleteWarningOpen(true),
          disabled: false,
          text: 'Delete'
        }}
        resetDataAfterClose={resetDataAfterClose}
      >
        <Box mb={40}>
          <RHFStyledInput
            name="name"
            error={Boolean(errors.name)}
            register={register}
            maxLength={MAX_ROLE_NAME_LENGTH}
            isRequired
            label="Role"
          />
        </Box>
      </FormModal>
    </>
  );
};

export default EditCreateRoleModal;
