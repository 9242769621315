import { FC, ForwardedRef, forwardRef, ReactNode } from 'react';

import { css } from '@mui/material';

import { styled } from '@mui/material/styles';

import { ButtonProps, StyledOutlined } from 'components/UIkit/atoms/Button/StyledButtons';
import { Text } from 'components/UIkit/atoms/Text';
import { withSpacing, WithSpacingProps } from 'components/UIkit/theme/spacing';

interface InternalProps extends ButtonProps {
  children: ReactNode;
  icon: ReactNode;
  subText: string;
  isActive?: boolean;
  forwardedRef?: ForwardedRef<HTMLButtonElement>;
}

type ExternalProps = Omit<InternalProps, 'forwardedRef'> & WithSpacingProps;

const OutlinedIconSubtext: FC<InternalProps> = ({
  onClick,
  disabled,
  icon,
  subText,
  isActive = false,
  testHook,
  children,
  forwardedRef
}) => (
  <Container
    onClick={onClick}
    disabled={disabled}
    ref={forwardedRef}
    data-test-hook={testHook}
    isActive={isActive}
  >
    {icon}
    <Text variant="button-medium-outline">{children}</Text>
    <>&nbsp;</>
    <Text variant="button-medium-outline" color="secondary">
      {subText}
    </Text>
  </Container>
);

const Container = styled(StyledOutlined)(
  ({ theme }) => css`
    box-shadow: ${theme.boxShadow.common};
    display: flex;
    align-items: center;

    svg {
      margin-right: ${theme.spacing(8)};
      color: ${theme.palette.primary.main};
    }

    &:disabled {
      box-shadow: none;

      svg {
        color: ${theme.palette.text.disabled};
      }
    }
  `
);

const OutlinedIconSubtextWithSpacing = withSpacing(OutlinedIconSubtext);

export const OutlinedIconSubtextButton: FC<ExternalProps> = forwardRef<
  HTMLButtonElement,
  ExternalProps
>((props, ref) => <OutlinedIconSubtextWithSpacing {...props} forwardedRef={ref} />);
