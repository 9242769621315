import { transformErrorUiProps } from 'errors';

import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

import PatientParser from 'parsers/PatientParser';

import Patient from 'models/Patient';

export enum Gender {
  Male = 'Male',
  Female = 'Female'
}

export interface EmrRowRequestData {
  patientId?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  patientMrn: string;
  patientSsn: string;
  phone: string;
  primaryPhysicianId: string;
  dob: string;
  gender: Gender;
  zipCode: string;
  address: string;
  city: string;
  state: string;
  locationId: number;
}

export interface CmRowRequestData {
  patientId?: string;
  emrPatientId?: string;
  primaryInsurance?: string;
  secondaryInsurance?: string;
  cancerDxCodes?: string;
  cancerDxDescription?: string;
  chronicDxCodes?: string;
  chronicDxDescription?: string;
  monthlyCareTimeMinutes?: number;
  visitsPast12Months?: number;
}

export type DxCodeData = {
  code: string;
  description: string;
};

export interface DxRequestData {
  patientId: number;
  dxCodes: DxCodeData[];
}

export interface ProviderData {
  name: string;
}

export interface LocationRequestData {
  name: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
}

const httpService = new HttpService('QA');

export class QaFetcher {
  static async resetInstitution() {
    return await httpService.delete({ url: API_URLS.RESET_INSTITUTION });
  }

  static async clearPatients() {
    return await httpService.delete({ url: API_URLS.CLEAR_PATIENTS });
  }

  static async createEmrRow(emrRowRequestData: EmrRowRequestData) {
    return await httpService.post({
      url: API_URLS.CREATE_EMR_ROW,
      data: emrRowRequestData
    });
  }

  static async createCmRow(cmRowRequestData: CmRowRequestData) {
    return await httpService.post({
      url: API_URLS.CREATE_CM_ROW,
      data: cmRowRequestData
    });
  }

  static async createDxRow(dxRequestData: DxRequestData) {
    return await httpService.post({
      url: API_URLS.CREATE_DX_ROW,
      data: dxRequestData
    });
  }

  static async adjustPatientTimeline(patientId: number, moveHours: number) {
    return await httpService.post({
      url: API_URLS.ADJUST_PATIENT_TIMELINE(patientId, moveHours)
    });
  }

  static async createProvider(providerData: ProviderData) {
    return await httpService.post({
      url: API_URLS.CREATE_PROVIDER,
      data: providerData
    });
  }

  static async addTreatment(emrPatientIds: string[]) {
    return await httpService.post({
      url: API_URLS.ADD_TREATMENT,
      data: {
        emrPatientIds
      }
    });
  }
  static async createLocation(locationRequestData: LocationRequestData) {
    return await httpService.post({
      url: API_URLS.CREATE_LOCATION,
      data: locationRequestData
    });
  }

  static async triggerRebrandingPhase(phase: number) {
    return await httpService.post({
      url: API_URLS.TRIGGER_REBRANDING,
      data: {
        phase
      }
    });
  }

  // For staging / demo only
  static async generateFakeReports() {
    return await httpService.get<boolean>({
      url: API_URLS.GENERATE_FAKE_REPORTS,
      transformError: transformErrorUiProps('Fail to generate fake reports')
    });
  }

  static async resetDemoEnvironment() {
    return await httpService.post({
      url: API_URLS.RESET_DEMO_ENVIRONMENT,
      transformError: transformErrorUiProps('Fail to reset demo environment')
    });
  }

  static async getMobileTokenForPatient(patientId: number) {
    return await httpService.get<string>({
      url: API_URLS.GET_MOBILE_TOKEN_FOR_PATIENT(patientId),
      transformError: transformErrorUiProps('Failed to get patient mobile token')
    });
  }

  static async getWebTokenForPatient(patientId: number) {
    return await httpService.get<{ longUrl: string; shortUrl: string }>({
      url: API_URLS.GET_WEB_EPRO_TOKEN_FOR_PATIENT(patientId),
      transformError: transformErrorUiProps('Failed to get patient web EPro token')
    });
  }

  static async getEnrollmentTokenForPatient(patientId: number) {
    return await httpService.get<{ longUrl: string; shortUrl: string }>({
      url: API_URLS.GET_ENROLLMENT_TOKEN_FOR_PATIENT(patientId),
      transformError: transformErrorUiProps('Failed to get patient enrollment token')
    });
  }

  static async generateNotifications() {
    return await httpService.post({
      url: API_URLS.GENERATE_NOTIFICATIONS,
      transformError: transformErrorUiProps('Failed to create notifications')
    });
  }

  static async adjustPatientAutoProtocol(patientId: number) {
    return await httpService.post({
      url: API_URLS.ADJUST_PATIENT_AUTO_PROTOCOL,
      data: {
        patientId
      },
      transformError: transformErrorUiProps('Failed to adjust auto protocol')
    });
  }

  static async setPatientDeceasedStatus(patientId: number) {
    return await httpService.patch({
      url: API_URLS.SET_PATIENT_DECEASED(patientId),
      transformError: transformErrorUiProps('Failed to set patients deceased status')
    });
  }

  static async getPatientList(): Promise<Patient[]> {
    return await httpService.get<Patient[]>({
      url: API_URLS.PATIENT_LIST,
      transformResponse: (patients) => patients.map(PatientParser.parsePatient)
    });
  }

  static async getServerVersion() {
    return await httpService.get({
      url: API_URLS.SERVER_VERSION
    });
  }
}

export default QaFetcher;
