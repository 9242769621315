// @ts-strict-ignore

import { FC, useMemo, useState } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackScheduledProtocolModalAnalyticsEvent } from 'analytics/events/scheduled-protocol-modal';
import { trackTabNavigationAnalyticsEvent } from 'analytics/events/tab-navigation';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useMount } from 'react-use';
import { reportProtocolModalTestSelectors } from 'tests/models/components/modal/report-protocol-modal/report-protocol-modal.selectors';

import { ReportProtocolModalTab } from 'tests/models/pages/patient-page/patient-page.types';

import { useStores } from 'mobx/hooks/useStores';

import {
  getTodayFormattedDate,
  HOUR_IN_MILLISECONDS,
  LONG_DATE_FORMAT,
  MINUTE_IN_MILLISECONDS,
  parseDateForInputField,
  timeOptions,
  WEEK_IN_MILLISECONDS
} from 'utils/DateUtils';

import { FEATURES } from 'constants/features';

import { DrugSpecificType, UnitType } from 'models/DrugSpecific';
import RegimenTemplate from 'models/RegimenTemplate';
import ScheduledProtocol, {
  ProtocolAutomationMode,
  ProtocolName,
  ProtocolOperationMode,
  ProtocolType
} from 'models/ScheduledProtocol';
import { SelectOption } from 'models/SelectOption';

import RegimenEditorModal from 'views/Modals/RegimenEditorModal';
import { DrugSpecificProtocolFields } from 'views/Widgets/DrugSpecificProtocolFields';
import {
  defaultDrugSpecificIntervalOptions,
  drugSpecificEndOptions,
  specificDrugToLabelMap
} from 'views/Widgets/DrugSpecificProtocolFields.constants';
import {
  defaultEndDateDurationUnitOptions,
  defaultEndOptions
} from 'views/Widgets/EndProtocolOptions';
import OralProtocolFields, { fillLocationOptions } from 'views/Widgets/OralProtocolFields';
import SymptomProtocolFields, {
  hybridIntervalOptions,
  intervalOptions
} from 'views/Widgets/SymptomProtocolFields';
import ToggleBar from 'views/Widgets/ToggleBar';

import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

import './ScheduledProtocolModal.scss';

interface ScheduleProtocolModalProps {
  isOpen: boolean;
  type: ProtocolType;
  protocol?: ScheduledProtocol;
  onScheduledProtocolSubmitted: (protocol: ScheduledProtocol) => void;
  onScheduledProtocolCanceled: () => void;
  isLoading?: boolean;
}

interface SymptomProtocolForm {
  selectedStartDate: string;
  selectedStartHour: SelectOption<number>;
  selectedEndOption: SelectOption<number>;
  selectedFrequency: SelectOption<number>;
  hybridFrequency: SelectOption<ProtocolAutomationMode>;
  frequencyMultiplier: number;
  numberOfOccurrences: number;
  selectedEndDate: string;
  weekdays: Set<number>;
  automaticFrequency: boolean;
}

interface OralProtocolForm {
  selectedStartDate: string;
  selectedStartHour: SelectOption<number>;
  weekdays: Set<number>;
  regimenId: SelectOption<number>;
  notes: string;
  fillFrequency: number;
  fillLocation: SelectOption<string>;
  numberOfOccurrences: number;
  selectedEndOption: SelectOption<number>;
  selectedFrequency: SelectOption<number>;
  selectedEndDate: string;
  regimen?: RegimenTemplate;
}

export interface DrugSpecificProtocolForm {
  drug: SelectOption<number>;
  type: SelectOption<number>;
  frequencyMultiplier: number;
  selectedFrequency: SelectOption<number>;
  selectedStartHour: SelectOption<number>;
  weekdays: Set<number>;
  selectedStartDate: string | moment.Moment;
  selectedEndOption: SelectOption<number>;
  selectedEndDate: string;
  numberOfOccurrences: number;
  endDateDuration: number;
  endDateDurationUnit: SelectOption<UnitType>;
}

const DEFAULT_PROTOCOL_START_HOUR = 8;

const ScheduleProtocolModal: FC<ScheduleProtocolModalProps> = (props) => {
  const { constantsStore, settingsStore } = useStores();
  const { protocol, isLoading } = props;
  const hasOralOncolytics = settingsStore.hasFeature(
    FEATURES.ORAL_ONCOLYTICS_AND_SYMPTOM_ASSESSMENT_TAB
  );
  const hasDrugSpecificFeature = settingsStore.hasFeature(FEATURES.DRUG_SPECIFIC);
  const name = protocol?.name || ProtocolName.symptom;
  const defaultTab = protocol?.type === ProtocolType.mobile ? name : ProtocolName.symptom;
  const [selectedProtocolTab, setSelectedProtocolTab] = useState(defaultTab);
  const [isRegimenModalOpen, setIsRegimenModalOpen] = useState(false);

  // currently we use conditional rendering for this modal (in 3 places).
  // need to change to update effect with a condition once we remove the conditional rendering
  // see https://expain.atlassian.net/browse/EH-4671
  useMount(function trackIsOpenOnMount() {
    trackScheduledProtocolModalAnalyticsEvent({
      tab: defaultTab,
      patientId: protocol?.patientId,
      action: AnalyticEventAction.Open
    });
  });

  useMount(function disablePhoneOralOption() {
    if (props.type === ProtocolType.phone) {
      setSelectedProtocolTab(ProtocolName.symptom);
    } else {
      setSelectedProtocolTab(defaultTab);
    }
  });

  const toggleOptions = useMemo(() => {
    const isMobile = props.type === ProtocolType.mobile;

    const oralOncolyticsOption = hasOralOncolytics
      ? [
          {
            value: ProtocolName.oralOncolytics,
            label: 'Oral Oncolytics',
            disabled: !isMobile,
            tooltipText: !isMobile
              ? 'Oral Oncolytics Report Protocols are only available via App / Web.'
              : '',
            testHook: reportProtocolModalTestSelectors.tab(ReportProtocolModalTab.Oral)
          }
        ]
      : [];

    const drugSpecificOption = hasDrugSpecificFeature
      ? [
          {
            value: ProtocolName.drugSpecific,
            label: 'Drug-Specific',
            disabled: !isMobile,
            tooltipText: !isMobile
              ? 'Drug-Specific Report Protocols are only available via App / Web.'
              : '',
            testHook: reportProtocolModalTestSelectors.tab(ReportProtocolModalTab.DrugSpecific)
          }
        ]
      : [];

    return [
      {
        value: ProtocolName.symptom,
        label: 'Symptom Assessment',
        testHook: reportProtocolModalTestSelectors.tab(ReportProtocolModalTab.Symptom)
      },
      ...oralOncolyticsOption,
      ...drugSpecificOption
    ];
  }, [hasOralOncolytics, hasDrugSpecificFeature, props.type]);

  const hasTabs = toggleOptions.length > 1;

  const [symptomDefaultValues, oralDefaultValues, drugSpecificDefaultValues] = useMemo((): [
    SymptomProtocolForm,
    OralProtocolForm,
    DrugSpecificProtocolForm
  ] => {
    const specificDrug = constantsStore.getSpecificDrugById(protocol?.info?.drugId);
    const specificDrugType = constantsStore.getSpecificDrugTemplate(
      specificDrug?.id,
      protocol?.info?.reportProtocolTemplateId
    );

    let timeUnit = WEEK_IN_MILLISECONDS;
    let multiplier = 1;
    if (
      (protocol?.name === ProtocolName.symptom &&
        protocol?.operationMode === ProtocolOperationMode.Manual) ||
      protocol?.name === ProtocolName.drugSpecific
    ) {
      const result = protocol.getIntervalAsMultiplication();
      timeUnit = result.timeUnit;
      multiplier = result.multiplier;
    }

    const selectedStartHour = timeOptions.find(
      (option) =>
        option.value ===
        (protocol?.info?.startingDate
          ? new Date(protocol.info.startingDate).getHours()
          : DEFAULT_PROTOCOL_START_HOUR)
    );

    const numberOfOccurrences = protocol?.info?.occurrences ? protocol.info.occurrences : 1;

    const drugSpecificEndOption = drugSpecificEndOptions.find(
      (option: SelectOption<number>) =>
        option.value ===
        (protocol?.info?.endType ? protocol.info.endType : ScheduledProtocol.END_TYPE_NEVER)
    );

    const nonDrugSpecificEndOption = defaultEndOptions.find(
      (option: SelectOption<number>) =>
        option.value ===
        (protocol?.info?.endType ? protocol.info.endType : ScheduledProtocol.END_TYPE_NEVER)
    );

    let selectedStartDate: string | moment.Moment;

    if (
      protocol?.info?.endType === ScheduledProtocol.END_TYPE_DURATION &&
      (timeUnit === HOUR_IN_MILLISECONDS || timeUnit === MINUTE_IN_MILLISECONDS)
    ) {
      selectedStartDate = moment(protocol?.info?.startingDate) || moment(new Date());
    } else {
      selectedStartDate = parseDateForInputField(
        protocol?.info?.startingDate || getTodayFormattedDate(LONG_DATE_FORMAT)
      ) as string;
    }

    const selectedEndDate = protocol?.info?.expirationDate
      ? parseDateForInputField(protocol?.info.expirationDate)
      : null;

    const isSymptom = protocol?.name === ProtocolName.symptom;
    const isOral = protocol?.name === ProtocolName.oralOncolytics;
    const isDrugSpecificProtocol = protocol?.name === ProtocolName.drugSpecific;

    return [
      {
        selectedStartDate: selectedStartDate as string,
        selectedStartHour: selectedStartHour,
        selectedEndOption: nonDrugSpecificEndOption,
        selectedFrequency: intervalOptions.find((option) => option.value === timeUnit) || {
          value: WEEK_IN_MILLISECONDS,
          label: 'week(s)'
        },
        hybridFrequency:
          hybridIntervalOptions.find((option) => option.value === protocol?.automationMode) ||
          hybridIntervalOptions[0],
        frequencyMultiplier: multiplier,
        numberOfOccurrences: numberOfOccurrences,
        selectedEndDate: selectedEndDate,
        weekdays:
          protocol?.info?.daysOfWeek && isSymptom
            ? protocol.info.daysOfWeek
            : [new Date().getDay()],
        automaticFrequency: Boolean(protocol?.operationMode === ProtocolOperationMode.Automatic)
      },
      {
        selectedStartDate: selectedStartDate as string,
        selectedStartHour: selectedStartHour,
        weekdays:
          protocol?.info?.daysOfWeek && isOral ? protocol.info.daysOfWeek : [new Date().getDay()],
        regimenId: constantsStore.regimenTemplatesForSelect.find(
          (option) => option.value === protocol?.info?.regimenId
        ),
        selectedFrequency: { label: 'Week', value: WEEK_IN_MILLISECONDS },
        fillFrequency: protocol?.info?.fillFrequency,
        notes: protocol?.info?.notes,
        fillLocation: fillLocationOptions.find(
          (option) => option.value === protocol?.info?.fillLocation
        ),
        selectedEndOption: nonDrugSpecificEndOption,
        selectedEndDate: selectedEndDate,
        numberOfOccurrences: numberOfOccurrences,
        regimen: protocol?.info?.regimen
      },
      {
        drug: specificDrug ? { value: specificDrug.id, label: specificDrug.name } : null,
        type: specificDrugType
          ? {
              value: specificDrugType.id,
              label: specificDrugToLabelMap[specificDrugType.type] || ''
            }
          : null,
        frequencyMultiplier: multiplier,
        selectedFrequency: defaultDrugSpecificIntervalOptions.find(
          (option) => option.value === timeUnit
        ),
        selectedStartHour: selectedStartHour,
        weekdays:
          protocol?.info?.daysOfWeek && isDrugSpecificProtocol
            ? protocol.info.daysOfWeek
            : [new Date().getDay()],
        selectedStartDate: selectedStartDate as moment.Moment,
        selectedEndOption: drugSpecificEndOption,
        selectedEndDate: selectedEndDate,
        numberOfOccurrences: numberOfOccurrences,
        endDateDuration:
          drugSpecificEndOption?.value === ScheduledProtocol.END_TYPE_DURATION
            ? protocol?.info?.duration
            : undefined,
        endDateDurationUnit:
          drugSpecificEndOption?.value === ScheduledProtocol.END_TYPE_DURATION
            ? defaultEndDateDurationUnitOptions.find(
                (option) => option.value === protocol?.info?.durationUnit
              )
            : undefined
      }
    ];
  }, [protocol, constantsStore]);

  const symptomMethods = useForm<SymptomProtocolForm>({
    defaultValues: symptomDefaultValues,
    shouldUnregister: false
  });

  const oralMethods = useForm<OralProtocolForm>({
    defaultValues: oralDefaultValues,
    shouldUnregister: false
  });

  const drugSpecificMethods = useForm<DrugSpecificProtocolForm>({
    defaultValues: drugSpecificDefaultValues,
    shouldUnregister: false
  });

  const [regimenId, regimen] = oralMethods.watch(['regimenId', 'regimen']);

  const regimenTemplate = constantsStore.regimens.get(regimenId?.value);
  const finalRegimen = regimen || regimenTemplate;

  const getBaseProtocol = function (code: number, name: ProtocolName) {
    const newProtocol = new ScheduledProtocol();
    newProtocol.id = protocol?.id;
    newProtocol.code = code;
    newProtocol.patientId = protocol?.patientId;
    newProtocol.lastReportIntervalAt = protocol?.lastReportIntervalAt;
    newProtocol.name = name;
    newProtocol.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return newProtocol;
  };

  const handleSubmitSymptomProtocol = (symptomProtocolForm: SymptomProtocolForm) => {
    trackScheduledProtocolModalAnalyticsEvent({
      tab: selectedProtocolTab,
      patientId: protocol?.patientId,
      action: getButtonText() === 'Save' ? AnalyticEventAction.Save : AnalyticEventAction.Replace
    });

    const newProtocol = getBaseProtocol(
      symptomProtocolForm.selectedFrequency.value === WEEK_IN_MILLISECONDS
        ? ScheduledProtocol.PROTOCOL_CODE_MULTIPLE_WEEKLY
        : ScheduledProtocol.PROTOCOL_CODE_LINEAR,
      ProtocolName.symptom
    );

    newProtocol.info = {
      expirationDate: symptomProtocolForm.selectedEndDate
        ? new Date(symptomProtocolForm.selectedEndDate)
        : null,
      startingDate: moment(symptomProtocolForm.selectedStartDate)
        .hour(symptomProtocolForm.selectedStartHour.value)
        .toDate(),
      timeBetweenReports:
        symptomProtocolForm.selectedFrequency.value * symptomProtocolForm.frequencyMultiplier,
      endType: symptomProtocolForm.selectedEndOption?.value,
      occurrences: symptomProtocolForm.numberOfOccurrences,
      daysOfWeek: symptomProtocolForm.weekdays
    };

    newProtocol.operationMode = symptomProtocolForm.automaticFrequency
      ? ProtocolOperationMode.Automatic
      : ProtocolOperationMode.Manual;
    newProtocol.automationMode =
      protocol?.isFullyAutomated() || !settingsStore.hasFeature(FEATURES.ACTIONS_HYBRID_PROTOCOL)
        ? ProtocolAutomationMode.Full
        : symptomProtocolForm.hybridFrequency.value;
    props.onScheduledProtocolSubmitted(newProtocol);
    clearAllErrors();
  };

  const clearAllErrors = () => {
    oralMethods.clearErrors();
    symptomMethods.clearErrors();
    drugSpecificMethods.clearErrors();
  };

  const selectTab = (tab: ProtocolName) => {
    trackTabNavigationAnalyticsEvent({
      action: AnalyticEventAction.TabSelect,
      tab: selectedProtocolTab,
      value: tab,
      virtual_page: 'report protocol modal'
    });

    setSelectedProtocolTab(tab);
  };

  const handleSubmitDrugSpecificProtocol = (
    drugSpecificFormFieldValues: DrugSpecificProtocolForm
  ) => {
    trackScheduledProtocolModalAnalyticsEvent({
      tab: selectedProtocolTab,
      patientId: protocol?.patientId,
      action: getButtonText() === 'Save' ? AnalyticEventAction.Save : AnalyticEventAction.Replace
    });

    const currentDrugHourlyTypeId = constantsStore.getSpecificDrugTypeId(
      drugSpecificFormFieldValues.drug.value,
      DrugSpecificType.Hourly
    );

    const currentDrugWeeklyCrsTypeId = constantsStore.getSpecificDrugTypeId(
      drugSpecificFormFieldValues.drug.value,
      DrugSpecificType.WeeklyCRS
    );

    const currentDrugWeeklyInfectionTypeId = constantsStore.getSpecificDrugTypeId(
      drugSpecificFormFieldValues.drug.value,
      DrugSpecificType.WeeklyInfection
    );

    const isHourlyProtocol = drugSpecificFormFieldValues.type.value === currentDrugHourlyTypeId;
    const isWeeklyCrsProtocol =
      drugSpecificFormFieldValues.type.value === currentDrugWeeklyCrsTypeId;
    const isWeeklyInfectionProtocol =
      drugSpecificFormFieldValues.type.value === currentDrugWeeklyInfectionTypeId;

    let protocolCode = ScheduledProtocol.PROTOCOL_CODE_LINEAR;

    if (
      (isWeeklyCrsProtocol || isWeeklyInfectionProtocol) &&
      drugSpecificFormFieldValues.selectedFrequency.value === WEEK_IN_MILLISECONDS
    ) {
      protocolCode = ScheduledProtocol.PROTOCOL_CODE_MULTIPLE_WEEKLY;
    }

    if (
      isHourlyProtocol &&
      drugSpecificFormFieldValues.selectedFrequency.value === HOUR_IN_MILLISECONDS
    ) {
      protocolCode = ScheduledProtocol.PROTOCOL_CODE_MULTIPLE_HOURLY;
    }

    const newProtocol = getBaseProtocol(protocolCode, ProtocolName.drugSpecific);

    newProtocol.info = {
      drugId: drugSpecificFormFieldValues.drug.value,
      reportProtocolTemplateId: drugSpecificFormFieldValues.type.value,
      timeBetweenReports:
        drugSpecificFormFieldValues.selectedFrequency.value *
        drugSpecificFormFieldValues.frequencyMultiplier,
      endType: drugSpecificFormFieldValues.selectedEndOption.value
    };

    if (
      drugSpecificFormFieldValues.selectedEndOption.value === ScheduledProtocol.END_TYPE_DURATION
    ) {
      newProtocol.info = {
        ...newProtocol.info,
        duration: drugSpecificFormFieldValues.endDateDuration,
        durationUnit: drugSpecificFormFieldValues.endDateDurationUnit?.value
      };
    }

    if (
      drugSpecificFormFieldValues.selectedEndOption.value === ScheduledProtocol.END_TYPE_OCCURRENCES
    ) {
      newProtocol.info = {
        ...newProtocol.info,
        occurrences: drugSpecificFormFieldValues.numberOfOccurrences
      };
    }

    if (drugSpecificFormFieldValues.selectedEndOption.value === ScheduledProtocol.END_TYPE_NEVER) {
      newProtocol.info = {
        ...newProtocol.info,
        expirationDate: null
      };
    }

    if (drugSpecificFormFieldValues.selectedEndOption.value === ScheduledProtocol.END_TYPE_DATE) {
      newProtocol.info = {
        ...newProtocol.info,
        expirationDate: new Date(drugSpecificFormFieldValues.selectedEndDate)
      };
    }

    if (isHourlyProtocol) {
      newProtocol.info = {
        ...newProtocol.info,
        startingDate: moment(drugSpecificFormFieldValues.selectedStartDate).toDate()
      };
    }

    if (isWeeklyCrsProtocol || isWeeklyInfectionProtocol) {
      newProtocol.info = {
        ...newProtocol.info,
        startingDate: moment(drugSpecificFormFieldValues.selectedStartDate)
          .hour(drugSpecificFormFieldValues.selectedStartHour.value)
          .toDate(),
        daysOfWeek: drugSpecificFormFieldValues.weekdays
      };
    }

    newProtocol.operationMode = ProtocolOperationMode.Manual;

    props.onScheduledProtocolSubmitted(newProtocol);
    clearAllErrors();
  };

  const handleSubmitOralProtocol = (oralProtocolForm: OralProtocolForm) => {
    trackScheduledProtocolModalAnalyticsEvent({
      tab: selectedProtocolTab,
      patientId: protocol?.patientId,
      action: getButtonText() === 'Save' ? AnalyticEventAction.Save : AnalyticEventAction.Replace
    });

    const newProtocol = getBaseProtocol(
      ScheduledProtocol.PROTOCOL_CODE_LINEAR,
      ProtocolName.oralOncolytics
    );
    newProtocol.info = {
      expirationDate: oralProtocolForm.selectedEndDate
        ? new Date(oralProtocolForm.selectedEndDate)
        : null,
      startingDate: moment(oralProtocolForm.selectedStartDate)
        .hour(oralProtocolForm.selectedStartHour.value)
        .toDate(),
      fillFrequency: oralProtocolForm.fillFrequency,
      fillLocation: oralProtocolForm.fillLocation.value,
      occurrences: oralProtocolForm.numberOfOccurrences,
      notes: oralProtocolForm.notes,
      daysOfWeek: oralProtocolForm.weekdays,
      regimenId: oralProtocolForm.regimenId.value,
      reportFrequency: oralProtocolForm.selectedFrequency.value,
      endType: oralProtocolForm.selectedEndOption.value,
      regimen: oralProtocolForm.regimen
    };

    newProtocol.operationMode = ProtocolOperationMode.Manual;
    props.onScheduledProtocolSubmitted(newProtocol);
    clearAllErrors();
  };

  const getProtocolSubmitFunction = () => {
    const handleInvalidSubmit = () =>
      trackScheduledProtocolModalAnalyticsEvent({
        tab: selectedProtocolTab,
        patientId: protocol?.patientId,
        action,
        value: 'missing fields'
      });

    const action =
      getButtonText() === 'Save' ? AnalyticEventAction.Save : AnalyticEventAction.Replace;

    if (selectedProtocolTab === ProtocolName.symptom) {
      return symptomMethods.handleSubmit(handleSubmitSymptomProtocol, handleInvalidSubmit);
    }

    if (selectedProtocolTab === ProtocolName.oralOncolytics) {
      return oralMethods.handleSubmit(handleSubmitOralProtocol, handleInvalidSubmit);
    }

    return drugSpecificMethods.handleSubmit(handleSubmitDrugSpecificProtocol, handleInvalidSubmit);
  };

  const getButtonText = () => {
    if (isLoading) {
      return 'Saving…';
    }
    return protocol?.id ? 'Replace' : 'Save';
  };

  const onRegimenSaved = (customRegimen: RegimenTemplate) => {
    oralMethods.setValue('regimen', customRegimen);
    setIsRegimenModalOpen(false);
  };

  const { formDefaultValues, formMethods } = useMemo(() => {
    if (selectedProtocolTab === ProtocolName.oralOncolytics) {
      return { formDefaultValues: oralDefaultValues, formMethods: oralMethods };
    }

    if (selectedProtocolTab === ProtocolName.drugSpecific) {
      return { formDefaultValues: drugSpecificDefaultValues, formMethods: drugSpecificMethods };
    }

    return { formDefaultValues: symptomDefaultValues, formMethods: symptomMethods };
  }, [
    selectedProtocolTab,
    drugSpecificDefaultValues,
    oralDefaultValues,
    symptomDefaultValues,
    oralMethods,
    drugSpecificMethods,
    symptomMethods
  ]);

  const title = `Report Protocol${
    Boolean(props.type) ? `: ${ScheduledProtocol.getProtocolDisplayName(props.type)}` : ''
  }`;

  return (
    <>
      <FormModal
        defaultValues={formDefaultValues}
        methods={formMethods}
        isOpen={props.isOpen}
        isInternalModalOpen={isRegimenModalOpen}
        title={title}
        confirmActions={[
          {
            onClick: getProtocolSubmitFunction(),
            text: getButtonText(),
            disabled: isLoading,
            testHook: reportProtocolModalTestSelectors.submitButton
          }
        ]}
        closeAction={{
          onClick: () => {
            props.onScheduledProtocolCanceled();
            clearAllErrors();
            trackScheduledProtocolModalAnalyticsEvent({
              tab: defaultTab,
              patientId: protocol?.patientId,
              action: AnalyticEventAction.Cancel
            });
          },
          disabled: false
        }}
        testHook={reportProtocolModalTestSelectors.modal}
      >
        <div className="scheduled-protocol-modal">
          {hasTabs && (
            <div className="toggle-bar-wrapper">
              <ToggleBar
                id="protocol-modal-toggle"
                className="protocol-toggle-bar"
                onOptionSelected={selectTab}
                options={toggleOptions}
                selected={selectedProtocolTab}
                isSquared
                size="small"
              />
            </div>
          )}

          {selectedProtocolTab === ProtocolName.symptom && (
            <SymptomProtocolFields type={props.type} isAutomatic={protocol?.isFullyAutomated()} />
          )}

          {selectedProtocolTab === ProtocolName.oralOncolytics && (
            <OralProtocolFields
              type={props.type}
              openRegimenModal={() => setIsRegimenModalOpen(true)}
              isRegimenModalOpen={isRegimenModalOpen}
            />
          )}

          {selectedProtocolTab === ProtocolName.drugSpecific && (
            <DrugSpecificProtocolFields protocol={protocol} />
          )}
        </div>
      </FormModal>

      {regimen && (
        <RegimenEditorModal
          regimen={finalRegimen}
          regimenTemplate={regimenTemplate}
          onSave={onRegimenSaved}
          onCancel={() => setIsRegimenModalOpen(false)}
          isOpen={isRegimenModalOpen}
        />
      )}
    </>
  );
};
export default observer(ScheduleProtocolModal);
