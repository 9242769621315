import { FC, ForwardedRef, forwardRef, ReactNode } from 'react';

import { css, Typography } from '@mui/material';

import { styled } from '@mui/material/styles';

import { Testable } from 'utils/TypeUtils';

import { Checkbox, CheckboxProps } from 'components/UIkit/atoms/Checkbox/Checkbox';
import { getContainerColor } from 'components/UIkit/atoms/Checkbox/utils';
import { withSpacing, WithSpacingProps } from 'components/UIkit/theme/spacing';

interface InternalProps extends CheckboxProps, Testable {
  label: ReactNode;
  forwardedRef?: ForwardedRef<HTMLInputElement>;
}

export type ExternalLabeledCheckboxProps = Omit<InternalProps, 'forwardedRef'> & WithSpacingProps;

const InternalLabeledCheckbox: FC<InternalProps> = ({
  id,
  label,
  checked,
  disabled = false,
  error = false,
  name,
  onChange,
  onKeyDown,
  readonly,
  testHook,
  forwardedRef
}) => (
  <StyledContainer disabled={disabled} error={error}>
    <Checkbox
      id={id}
      name={name}
      disabled={disabled}
      readonly={readonly}
      error={error}
      checked={checked}
      onChange={onChange}
      onKeyDown={onKeyDown}
      testHook={testHook}
      ref={forwardedRef}
    />

    <Typography
      variant="form-text"
      htmlFor={id}
      component="label"
      data-test-hook={`${testHook}_label`}
    >
      {label}
    </Typography>
  </StyledContainer>
);

const StyledContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'error' && prop !== 'disabled'
})<{
  disabled: boolean;
  error: boolean;
}>(
  ({ theme, disabled, error }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(8)};
    color: ${getContainerColor(theme, disabled, error)};

    label {
      margin-bottom: 0;
      cursor: ${disabled ? 'initial' : 'pointer'};
    }
  `
);

const LabeledCheckboxWithSpacing = withSpacing(InternalLabeledCheckbox);

export const LabeledCheckbox: FC<ExternalLabeledCheckboxProps> = forwardRef<
  HTMLInputElement,
  ExternalLabeledCheckboxProps
>((props, ref) => <LabeledCheckboxWithSpacing {...props} forwardedRef={ref} />);
