import { ChangeEvent, FC } from 'react';

import { Box, css } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AnalyticEventAction } from 'analytics';
import { trackInputUsageAnalyticsEvent } from 'analytics/events/input-usage';
import { observer } from 'mobx-react';

import { useController, useFormContext } from 'react-hook-form';

import { useStores } from 'mobx/hooks/useStores';

import { DAY_IN_MILLISECONDS, MONTH_IN_MILLISECONDS, WEEK_IN_MILLISECONDS } from 'utils/DateUtils';

import { FEATURES } from 'constants/features';

import { ProtocolAutomationMode, ProtocolType } from 'models/ScheduledProtocol';

import EndProtocolOptions from 'views/Widgets/EndProtocolOptions';
import ProtocolIntervalFields from 'views/Widgets/ProtocolIntervalFields';
import MessageDivider from 'views/Widgets/ProtocolTimesMessageDivider';
import { RHFStyledInput } from 'views/Widgets/StyledInput';

import StyledToggleButton, { LabelPosition } from 'views/Widgets/StyledToggleButton';

import Icon from 'components/Icons/Icon';
import { SimpleTooltip } from 'components/Tooltip';
import { FormAutocomplete } from 'components/UIkit/atoms/Dropdown';

import { Text } from 'components/UIkit/atoms/Text';

import './SymptomProtocolFields.scss';

interface SymptomProtocolFieldsProps {
  type: ProtocolType;
  isAutomatic: boolean;
}

export const hybridIntervalOptions = [
  { value: ProtocolAutomationMode.Weekly, label: 'week' },
  { value: ProtocolAutomationMode.Monthly, label: 'month' }
];

export const intervalOptions = [
  { value: DAY_IN_MILLISECONDS, label: 'day(s)' },
  { value: WEEK_IN_MILLISECONDS, label: 'week(s)' },
  { value: MONTH_IN_MILLISECONDS, label: 'month(s)' }
];
const SymptomProtocolFields: FC<SymptomProtocolFieldsProps> = (props) => {
  const { settingsStore } = useStores();
  const isAutoEnrollmentToggleButtonDisabled = props.type === ProtocolType.phone;
  const showAutoEnrollmentToggleTooltip = props.type === ProtocolType.mobile;
  const methods = useFormContext();

  const [
    weekdays,
    selectedStartDate,
    selectedStartHour,
    selectedFrequency,
    currentAutomaticFrequency
  ] = methods.watch([
    'weekdays',
    'selectedStartDate',
    'selectedStartHour',
    'selectedFrequency',
    'automaticFrequency'
  ]);

  const { field } = useController({
    control: methods.control,
    name: 'automaticFrequency'
  });

  const handleAutomaticFrequencyChanged = (event: ChangeEvent<HTMLInputElement>) => {
    trackInputUsageAnalyticsEvent({
      action: AnalyticEventAction.Update,
      type: 'switch',
      value: 'Automatic Frequency',
      selected_value: currentAutomaticFrequency ? 'off' : 'on'
    });

    field.onChange(event);
  };

  const hasAutoEnrollment = settingsStore.hasFeature(FEATURES.AUTO_PATIENT_ENROLLMENT);
  return (
    <div className="symptom-fields-container">
      <Text
        variant="body2"
        color="disabled"
        mb={20}
        display="inline-block"
        textTransform="uppercase"
      >
        Report Schedule
      </Text>

      {hasAutoEnrollment && (
        <StyledSimpleTooltip
          disabled={!isAutoEnrollmentToggleButtonDisabled}
          title={<Box p={20}>Automatic Frequency is only available via App / Web.</Box>}
        >
          <StyledToggleButton
            label={
              <Box display="flex" alignItems="center">
                <Text
                  mr={4}
                  variant="body1"
                  color={isAutoEnrollmentToggleButtonDisabled ? 'disabled' : 'primary'}
                >
                  Automatic Frequency
                </Text>

                {showAutoEnrollmentToggleTooltip && (
                  <SimpleTooltip
                    title={
                      <StyledTooltipText>
                        <li>
                          Report is requested 2 or 4 days post-treatment (varies by treatment type).
                        </li>
                        <li>If that falls on a weekend, request is pushed to Monday.</li>
                        <li>
                          If no subsequent treatment is detected, report is requested a week after
                          the last one.
                        </li>
                        <li>
                          If no treatment is detected after 3 months, report is requested every
                          other week.
                        </li>
                        <li>
                          If no treatment is detected after 6 months, report is requested monthly.
                        </li>
                      </StyledTooltipText>
                    }
                  >
                    <Icon.Info />
                  </SimpleTooltip>
                )}
              </Box>
            }
            labelPosition={LabelPosition.RIGHT}
            onChange={handleAutomaticFrequencyChanged}
            checked={field.value}
            disabled={isAutoEnrollmentToggleButtonDisabled}
          />
        </StyledSimpleTooltip>
      )}

      {currentAutomaticFrequency &&
        hasAutoEnrollment &&
        settingsStore.hasFeature(FEATURES.ACTIONS_HYBRID_PROTOCOL) && (
          <StyledContainer>
            <Text variant="form-text" color={props.isAutomatic ? 'disabled' : 'primary'}>
              Report every
            </Text>
            <FormAutocomplete
              sortAlphabetically={false}
              isClearable={false}
              options={hybridIntervalOptions}
              name="hybridFrequency"
              isDisabled={props.isAutomatic}
            />
            <Text variant="form-text" color={props.isAutomatic ? 'disabled' : 'primary'}>
              until the first treatment is detected.
            </Text>
          </StyledContainer>
        )}
      {!currentAutomaticFrequency && (
        <>
          <ProtocolIntervalFields
            showDaySelector={selectedFrequency?.value === WEEK_IN_MILLISECONDS}
          />

          <MessageDivider
            startDate={selectedStartDate}
            startHour={selectedStartHour.value}
            weekdays={weekdays}
            intervalFrequency={selectedFrequency?.value}
            isPhoneProtocol={props.type === ProtocolType.phone}
          />

          <Text
            variant="body2"
            color="disabled"
            mb={12}
            display="inline-block"
            textTransform="uppercase"
          >
            Report Activation
          </Text>

          <div className="inner-modal-block">
            <RHFStyledInput
              type="date"
              label="Activation of Protocol"
              isRequired
              register={methods.register}
              error={Boolean(methods.formState.errors.selectedStartDate)}
              name="selectedStartDate"
            />

            <div className="ending-section">
              <EndProtocolOptions minEndDate={selectedStartDate} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const StyledContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    width: fit-content;
    align-content: center;
    align-items: center;
    margin-top: ${theme.spacing(20)};
    gap: ${theme.spacing(20)};
  `
);

const StyledSimpleTooltip = styled(SimpleTooltip)`
  width: fit-content;
`;

const StyledTooltipText = styled(`ul`)(
  ({ theme }) => css`
    margin: 0;
    padding: ${theme.spacing(16, 24)};
  `
);

export default observer(SymptomProtocolFields);
