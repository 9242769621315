// @ts-strict-ignore
import { useState } from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CLINICIAN_EDIT_CREATE_ERRORS } from 'errors';

import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';

import { useStores } from 'mobx/hooks/useStores';

import { UserType, userTypeOptions, userTypeStandardOption } from 'utils/AccountUtils';
import { showToast } from 'utils/UserMessageUtils';
import { isValidName } from 'utils/ValidationUtils';
import * as ValidationUtils from 'utils/ValidationUtils';

import { NO_CREDENTIALS_ID } from 'constants/clinicanCredentials.const';

import Department from 'models/Department';

import { useClinicianCredentialsOptions } from 'hooks/useClinicianCredentials';

import { RHFStyledInput } from 'views/Widgets/StyledInput';
import StyledPhoneInput from 'views/Widgets/StyledPhoneInput';
import { ISelectOption } from 'views/Widgets/StyledSelect';

import { FormErrors } from 'components/Forms';
import { Separator } from 'components/Separator';

import { FormAutocomplete, FormMultiAutocomplete } from 'components/UIkit/atoms/Dropdown';
import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

export interface IDoctorSignUpFields {
  firstName: string;
  lastName: string;
  credential: ISelectOption<number>;
  email: string;
  phone: string;
  countryCode: string;
  password: string;
  temporaryPassword: string;
  userType: ISelectOption<UserType>;
  selectedTeams: ISelectOption<number>[];
}

export interface IDoctorSignUpModalProps {
  isOpen: boolean;
  close: () => void;
}

export const DoctorSignUpModal = observer(({ isOpen, close }: IDoctorSignUpModalProps) => {
  const { departmentsStore, cliniciansStore } = useStores();
  const methods = useForm<IDoctorSignUpFields>({
    shouldUnregister: true
  });
  const { register, handleSubmit, getValues, formState } = methods;
  const formDefaultValues = {
    phone: '',
    countryCode: '+1',
    userType: userTypeStandardOption
  };

  const { errors } = formState;

  const [formError, setFormError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const clinicianCredentialOptions = useClinicianCredentialsOptions();

  const teamOptions = departmentsStore.root
    ? departmentsStore.getInstitutionDepartmentsAsArray().map((department: Department) => ({
        value: department.id,
        label: department.name
      }))
    : [];

  const onSubmit = async (data: IDoctorSignUpFields) => {
    setFormError('');
    setIsLoading(true);
    try {
      const credentialId =
        (data.credential?.value !== NO_CREDENTIALS_ID && data.credential?.value) || null;
      await cliniciansStore.inviteClinician({
        email: data.email,
        firstName: data.firstName.trim(),
        lastName: data.lastName.trim(),
        credentialId: credentialId,
        selectedTeams: (data.selectedTeams || []).map((team) => team.value),
        phone: data.countryCode + data.phone,
        password: data.password
      });
      showToast({ message: 'Clinician Successfully Enrolled' });
      close();
    } catch (error) {
      setIsLoading(false);

      if (CLINICIAN_EDIT_CREATE_ERRORS.includes(error.name)) {
        setFormError(error?.ui?.title || 'Something went wrong');
      } else {
        throw error;
      }
    }
  };

  const validateSamePassword = (confirmPassword: string) => {
    return confirmPassword !== getValues('password') ? 'Passwords do not match' : true;
  };

  return (
    <FormModal
      defaultValues={formDefaultValues}
      methods={methods}
      isOpen={isOpen}
      title={`Enroll a Clinician: ${departmentsStore.root ? departmentsStore.root.name : ''}`}
      confirmActions={[
        {
          onClick: handleSubmit(onSubmit),
          text: isLoading ? 'Saving...' : 'Enroll',
          disabled: isLoading,
          testHook: 'submit-btn'
        }
      ]}
      closeAction={{ onClick: close, disabled: false }}
      resetDataAfterClose={() => {
        setIsLoading(false);
        setFormError('');
      }}
    >
      <StyledBox mb={8}>
        <Row>
          <Col xs={12} sm={5} lg={5}>
            <RHFStyledInput
              isRequired
              name="firstName"
              label="First Name"
              register={register}
              error={Boolean(errors.firstName)}
              errorMessage={errors.firstName?.message}
              validate={isValidName}
              testHook="firstName"
            />
          </Col>
          <Col xs={12} sm={5} lg={5} className="pl-1">
            <RHFStyledInput
              isRequired
              name="lastName"
              label="Last Name"
              register={register}
              error={Boolean(errors.lastName)}
              errorMessage={errors.lastName?.message}
              validate={isValidName}
              testHook="lastName"
            />
          </Col>
          <Col xs={12} sm={2} lg={2} className="pl-1">
            <FormAutocomplete
              label="Credentials"
              name="credential"
              placeholder={null}
              sortAlphabetically={false}
              options={clinicianCredentialOptions}
            />
          </Col>
        </Row>
        <Separator />
        <Row>
          <Col xs={12} sm={6} lg={6}>
            <StyledPhoneInput
              disabled={false}
              error={Boolean(errors.phone)}
              label="Phone"
              testHook="phone"
            />
          </Col>
          <Col xs={12} sm={6} lg={6}>
            <RHFStyledInput
              isRequired
              name="email"
              type="email"
              label="Email"
              register={register}
              error={Boolean(errors.email)}
              errorMessage={errors.email?.message}
              validate={ValidationUtils.isEmail}
              testHook="email"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} lg={6}>
            <FormAutocomplete
              name="userType"
              label="Type"
              isRequired
              isClearable={false}
              isDisabled // TODO: allow changing type on enrollment
              options={userTypeOptions}
              testHook="userType"
              labelTooltip="'Manager' users may access the Practice Manager, where they can manage users,
                                     care teams, providers, and locations.
                                     In addition, they can edit Shared Triage Rules for the entire practice.
                                     They cannot reset passwords. Only 'Admin' users may do so."
            />
          </Col>
          <Col xs={12} sm={6} lg={6}>
            <FormMultiAutocomplete
              label="Care Team Assignment (Optional)"
              placeholder="Select Care Team Assignment..."
              name="selectedTeams"
              options={teamOptions}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12} sm={6} lg={6}>
            <RHFStyledInput
              showErrorOutside
              isRequired
              name="password"
              type="password"
              label="Password"
              register={register}
              error={Boolean(errors.password)}
              validate={ValidationUtils.isStrongPassword}
              hint="At Least: 8 characters (Lowercase & Uppercase), 1 Number, 1 Special Character"
              testHook="password"
            />
          </Col>
          <Col xs={12} sm={6} lg={6}>
            <RHFStyledInput
              showErrorOutside
              isRequired
              name="temporaryPassword"
              type="password"
              label="Confirm Password"
              register={register}
              error={Boolean(errors.temporaryPassword)}
              validate={validateSamePassword}
              testHook="temporaryPassword"
            />
          </Col>
        </Row>
      </StyledBox>
      <FormErrors errors={formError ? [formError] : null} />
    </FormModal>
  );
});

const StyledBox = styled(Box)`
  .styled-input {
    margin-bottom: 1.25rem;
  }
`;
