import { ISelectOption } from 'views/Widgets/StyledSelect';

export enum UserType {
  STANDARD = 'Standard',
  CHIEF_NURSE = 'Chief Nurse',
  ADMIN = 'Admin'
}

export const userTypeToOption = {
  [UserType.STANDARD]: UserType.STANDARD,
  [UserType.CHIEF_NURSE]: 'Manager',
  [UserType.ADMIN]: UserType.ADMIN
};

export const USER_TYPE_OPTIONS: ISelectOption<UserType>[] = Object.values(UserType).map(
  (status) => {
    return {
      label: userTypeToOption[status],
      value: status
    };
  }
);

export const userTypeOptions = USER_TYPE_OPTIONS.map((option) => ({
  ...option,
  isDisabled: option.value === UserType.ADMIN
}));

export const userTypeStandardOption = userTypeOptions.find(
  (option) => option.value === UserType.STANDARD
);
