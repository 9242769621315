// @ts-strict-ignore
import {
  Breadcrumb,
  init,
  reactRouterV5Instrumentation,
  BrowserTracing,
  configureScope,
  Scope,
  addBreadcrumb,
  captureException,
  withSentryRouting
} from '@sentry/react';
import { ErrorWithUi } from 'errors';
import { History } from 'history';
import { Route as ReactRouterDomRoute } from 'react-router-dom';

import { SENTRY, API_HOST, VERSION } from 'constants/config';

export enum CustomSentryCategories {
  DEBUG = 'DEBUG'
}

export const initSentry = (history: History) => {
  init({
    ...SENTRY,
    release: `${VERSION}-${SENTRY.release}`,
    // uncomment to test sentry outgoing messages locally
    // beforeSend: (event: Sentry.Event) => {
    //   console.log(event);
    //   return null;
    // },
    ignoreErrors: ['User Unauthorized', 'No Internet Connection'], // todo: remove
    integrations: [
      new BrowserTracing({
        routingInstrumentation: reactRouterV5Instrumentation(history),
        tracePropagationTargets: [API_HOST.replace(/https?:\/\//, '')]
      })
    ]
  });
};

export function setSentryUser(userId: number) {
  configureScope((scope: Scope) => {
    scope.setUser({ id: String(userId) });
  });
}

export function reportBreadcrumb(breadcrumb: Breadcrumb) {
  addBreadcrumb(breadcrumb);
}

export function reportError(err: Error | ErrorWithUi) {
  reportBreadcrumb({
    level: 'error',
    type: 'error',
    category: 'error',
    message: err.message,
    data: err
  });

  if (err instanceof ErrorWithUi) {
    err = convertToSentryError(err);
  }
  captureException(err);
}

export function convertToSentryError(error: ErrorWithUi): Error {
  const errorPrototype = Object.getPrototypeOf(error);
  const clone = Object.assign(Object.create(errorPrototype), { ...error });
  clone.stack = error.stack;
  //title is HIPPA compliant
  clone.message = error.ui.title;
  return clone;
}

export const Route = withSentryRouting(ReactRouterDomRoute);
