import { FC, KeyboardEvent, ReactNode } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { CheckboxProps, LabeledCheckbox } from 'components/UIkit/atoms/Checkbox';

export interface FormLabeledCheckboxProps extends Omit<CheckboxProps, 'id'> {
  label: ReactNode;
  required?: boolean;
  name: string;
  id?: string;
}

export const FormLabeledCheckbox: FC<FormLabeledCheckboxProps> = ({
  label,
  name,
  disabled,
  required,
  onKeyDown,
  onChange,
  id
}) => {
  const {
    control,
    formState: { errors },
    getValues,
    setValue
  } = useFormContext();
  const error = Boolean(errors[name]);

  const handleKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter') {
      const checkboxValue = getValues(name);
      setValue(name, !checkboxValue);
    }
    onKeyDown && onKeyDown(event);
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required
      }}
      render={({ field: { value: checked, ref, onChange: fieldOnChange } }) => (
        <LabeledCheckbox
          id={id || name}
          name={name}
          onChange={(event) => {
            fieldOnChange(event);

            if (onChange) {
              onChange(event);
            }
          }}
          checked={checked}
          ref={ref}
          disabled={disabled}
          label={label}
          error={error}
          onKeyDown={handleKeyDown}
        />
      )}
    />
  );
};
